import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'table',
    'companyType',
    'bucketContainer',
    'fiscalContainer',
    'projectContainer',
    'companyStatusContainer',
    'manageButton'
  ]

  // Switch tabs on the company index page.
  switch (event) {
    this.companyTypeTarget.value = event.params.active

    if (event.params.active === 'public_companies') {
      this.tableTarget.classList.remove('private-companies-view')
      this.bucketContainerTarget.style.display = 'inline-block'
      this.fiscalContainerTarget.style.display = 'inline-block'
      this.projectContainerTarget.style.display = 'inline-block'
      this.companyStatusContainerTarget.style.display = 'inline-block'
      this.manageButtonTarget.hidden = false
    } else {
      this.tableTarget.classList.remove('public-companies-view')
      this.bucketContainerTarget.style.display = 'none'
      this.fiscalContainerTarget.style.display = 'none'
      this.projectContainerTarget.style.display = 'none'
      this.companyStatusContainerTarget.style.display = 'none'
      this.manageButtonTarget.hidden = true
    }

    this.tableTarget.classList.add(event.params.class)
  }
}
