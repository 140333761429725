import BaseController from '../base_controller'

export default class XBRLSegmentsDrawerFullscreenController extends BaseController {
  static targets = ['toggleFullscreenButton']

  static values = {
    nextState: { type: String, default: 'fullscreen' }
  }

  toggleFullscreen () {
    if (this.nextStateValue === 'fullscreen') {
      this.enableFullscreen()
    } else {
      this.disableFullscreen()
    }
  }

  enableFullscreen () {
    const citationsContent = document.querySelector('[data-ujs-id="assistant_citations_container"]')
    const drawerContainer = document.querySelector('[data-ujs-id="xbrl_segments_drawer_container"]')

    this.nextStateValue = null
    this.element.style.height = '100%'
    citationsContent.style.flex = '0'
    drawerContainer.classList.remove('flex-0')
    drawerContainer.classList.add('flex-1')
    this.toggleFullscreenButtonTarget.querySelector('i').innerHTML = 'fullscreen_exit'
  }

  disableFullscreen () {
    const citationsContent = document.querySelector('[data-ujs-id="assistant_citations_container"]')
    const drawerContainer = document.querySelector('[data-ujs-id="xbrl_segments_drawer_container"]')

    this.nextStateValue = 'fullscreen'
    this.element.style.height = ''
    citationsContent.style.flex = ''
    drawerContainer.classList.remove('flex-1')
    drawerContainer.classList.add('flex-0')
    this.toggleFullscreenButtonTarget.querySelector('i').innerHTML = 'fullscreen'
  }
}
