import BaseController from './base_controller'

export default class ScrollSyncController extends BaseController {
  static targets = ['scrollContainer']

  connect () {
    this.scrollContainerTargets.forEach(scrollContainer => {
      scrollContainer.addEventListener('scroll', event => {
        const scrollTop = event.target.scrollTop
        const scrollLeft = event.target.scrollLeft
        this.scrollContainerTargets.forEach(scrollContainer => {
          scrollContainer.scrollTop = scrollTop
          scrollContainer.scrollLeft = scrollLeft
        })
      })
    })
  }
}
