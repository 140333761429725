import { Controller } from '@hotwired/stimulus'
import * as PDFJS from 'pdfjs-dist'
import { useResize } from 'stimulus-use'
import tippy from 'tippy.js'
import { fire } from '../lib/form_events'
import {
  EventBus,
  PDFFindController,
  PDFViewer,
  PDFLinkService
} from 'pdfjs-dist/web/pdf_viewer'

const FindState = {
  FOUND: 0,
  NOT_FOUND: 1,
  WRAPPED: 2,
  PENDING: 3
}

export default class extends Controller {
  static targets = ['citationFromTableForm', 'name', 'currentPage', 'totalPages', 'currentZoom', 'search',
    'searchCount', 'nextMatch', 'prevMatch', 'searchBox']

  static values = {
    url: String,
    tablesJson: Object // tables grouped by page
  }

  findState = null

  connect () {
    useResize(this)
    this.eventBus = new EventBus()
    this.linkService = new PDFLinkService({ eventBus: this.eventBus })
    this.findController = new PDFFindController({
      eventBus: this.eventBus,
      linkService: this.linkService
    })

    this.downloadDocument()
    this.eventBus.on('pagechanging', this.pageChanging)
    this.eventBus.on('pagerendered', this.pageRendered)
    this.eventBus.on('scalechanging', this.scaleChanging)
    this.eventBus.on('updatefindmatchescount', this.updateFindMatchesCount)
    this.eventBus.on('updatefindcontrolstate', this.updateFindControlState)
    this.eventBus.on('textlayerrendered', this.textLayerRendered)

    this.render()
  }

  nextMatchTargetConnected (target) {
    this.enableMatchTraversal(target, !!this.findController?.pageMatchesLength)
  }

  prevMatchTargetConnected (target) {
    this.enableMatchTraversal(target, !!this.findController?.pageMatchesLength)
  }

  resize () {
    // wait until the container is loaded to render
    this.pdfViewer?.update()
  }

  downloadDocument () {
    this.pdfPromise = PDFJS.getDocument(this.urlValue).promise
  }

  search ({ currentTarget, findPrevious = false }) {
    if (currentTarget.value !== this.findController.state?.query) {
      this.searchCountTarget.classList.add('loading')
    }

    setTimeout(() => {
      this.eventBus.dispatch('find', {
        highlightAll: true,
        phraseSearch: true,
        caseSensitive: false,
        findPrevious,
        type: 'again',
        query: currentTarget.value
      })
    }, 0)
  }

  toggleSearch () {
    if (this.searchBoxTarget.classList.contains('hidden')) {
      this.showSearch()
    } else {
      this.hideSearch()
    }
  }

  showSearch (event) {
    // dont take over browser search if not viewing pdf
    if (this.element.offsetParent === null) return

    event?.preventDefault()
    this.searchBoxTarget.classList.remove('hidden')
    this.searchTarget.focus()

    if (!this.searchTarget.value) return

    // highlight previous search
    this.eventBus.dispatch('find', {
      highlightAll: true,
      phraseSearch: true,
      caseSensitive: false,
      type: 'highlightallchange',
      query: this.searchTarget.value
    })
  }

  hideSearch () {
    this.searchBoxTarget.classList.add('hidden')
    this.eventBus.dispatch('findbarclose')
  }

  prevMatch () {
    this.search({ currentTarget: this.searchTarget, findPrevious: true })
  }

  nextMatch () {
    this.search({ currentTarget: this.searchTarget })
  }

  setPage ({ currentTarget }) {
    this.pdfViewer.currentPageNumber = parseInt(currentTarget.value)
  }

  setZoom ({ currentTarget }) {
    if (!currentTarget.value.endsWith('%')) {
      currentTarget.value = currentTarget.value + '%'
    }

    const newZoom = parseFloat(currentTarget.value.match(/\d+/)) / 100
    this.pdfViewer.currentScaleValue = newZoom
  }

  toggleFit ({ currentTarget }) {
    const nextFit = currentTarget.innerText === 'vertical_distribute' ? 'page-height' : 'page-width'
    this.pdfViewer.currentScaleValue = nextFit
    currentTarget.innerText = nextFit === 'page-height' ? 'horizontal_distribute' : 'vertical_distribute'
  }

  increaseZoom () {
    const currentZoom = parseFloat(this.currentZoomTarget.value.match(/\d+/))
    const newZoom = Math.floor(currentZoom / 10) * 10 + 10
    this.currentZoomTarget.value = `${newZoom}%`
    this.setZoom({ currentTarget: this.currentZoomTarget })
  }

  decreaseZoom () {
    const currentZoom = parseFloat(this.currentZoomTarget.value.match(/\d+/))
    const newZoom = Math.ceil(currentZoom / 10) * 10 - 10
    this.currentZoomTarget.value = `${newZoom}%`
    this.setZoom({ currentTarget: this.currentZoomTarget })
  }

  rotatePages () {
    this.pdfViewer.pagesRotation -= 90
  }

  download ({ currentTarget }) {
    currentTarget.setAttribute('disabled', '')
    fetch(this.urlValue)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.style.display = 'none'
        a.download = this.nameTarget.innerText
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .then(() => {
        currentTarget.removeAttribute('disabled')
      })
  }

  updateFindControlState = ({ source, state, previous, matchesCount, rawQuery }) => {
    this.findState = state

    if (state === FindState.PENDING) {
      this.searchCountTarget.classList.add('loading')
    } else {
      this.searchCountTarget.classList.remove('loading')
      this.updateFindMatchesCount({ source, matchesCount })
    }

    if (state === FindState.FOUND) {
      this.enableMatchTraversal(this.prevMatchTarget)
      this.enableMatchTraversal(this.nextMatchTarget)
    } else {
      this.enableMatchTraversal(this.prevMatchTarget, false)
      this.enableMatchTraversal(this.nextMatchTarget, false)
    }
  }

  updateFindMatchesCount = ({ source, matchesCount }) => {
    if (this.searchBoxTarget.classList.contains('hidden')) return

    const { current, total } = matchesCount
    this.searchCountTarget.innerText = `${current}/${total}`
  }

  scaleChanging = ({ scale }) => {
    this.currentZoomTarget.value = Math.round(scale * 100) + '%'
  }

  pageChanging = ({ pageNumber }) => {
    this.currentPageTarget.value = pageNumber
  }

  pageRendered = ({ pageNumber }) => {
    const pageView = this.pdfViewer.getPageView(pageNumber - 1)
    this.pdfViewer.currentScaleValue ||= 'auto'
    this.totalPagesTarget.innerText = this.pdfViewer.pagesCount

    pageView.textLayer.div.addEventListener('copy', this.copyOverride)

    this.handleTables(pageView, pageNumber)
  }

  textLayerRendered = ({ source }) => {
    source.textLayer.div.querySelectorAll('span[role="presentation"]').forEach(text => {
      // text sometimes is not in document order, set the zIndex so we can oversize the text spans for better selection
      // without blocking elements further down the page
      const zIndex = parseInt(getComputedStyle(text).top.match(/^\d+/))
      text.style.zIndex = zIndex
    })
  }

  copyOverride = (event) => {
    event.preventDefault()
    event.stopImmediatePropagation()

    const text = window.getSelection().toString().replace(/\n/g, ' ')
    event.clipboardData.setData('text/plain', text)
  }

  enableMatchTraversal (target, enable = true) {
    if (enable) {
      target.removeAttribute('disabled')
    } else {
      target.setAttribute('disabled', '')
    }
  }

  async render () {
    if (this.pdfViewer) return

    const pdf = await this.pdfPromise
    const container = document.createElement('DIV')
    const viewer = document.createElement('DIV')

    container.classList.add('pdfViewer')
    container.appendChild(viewer)

    this.element.append(container)

    this.pdfViewer = new PDFViewer({
      eventBus: this.eventBus,
      linkService: this.linkService,
      findController: this.findController,
      downloadManager: this.downloadManager,
      textLayerMode: 2,
      container,
      viewer
    })

    this.linkService.setViewer(this.pdfViewer)
    this.pdfViewer.setDocument(pdf)
    this.linkService.setDocument(pdf, null)
  }

  async handleTables (pageView, pageNum) {
    const container = document.createElement('div')
    container.setAttribute('class', 'tablesLayer')
    pageView.div.appendChild(container)

    const tables = this.tablesJsonValue[pageNum]
    if (!tables) return

    tables.forEach(table => {
      const tableDiv = document.createElement('div')
      tableDiv.setAttribute('class', 'table')
      tableDiv.dataset.tableJson = JSON.stringify(table.tabular_data)
      tableDiv.dataset.pageNumber = pageNum

      tableDiv.style.width = `${table.bounding_box.width * 100}%`
      tableDiv.style.height = `${table.bounding_box.height * 100}%`
      tableDiv.style.top = `${table.bounding_box.top * 100}%`
      tableDiv.style.left = `${table.bounding_box.left * 100}%`

      container.appendChild(tableDiv)
      this.createTableTooltip(tableDiv)
    })
  }

  createTableTooltip (tableDiv) {
    tippy(tableDiv, {
      content: '<div class="copy-to-citation-button">Extract Table</div>',
      placement: 'bottom-end',
      interactive: true,
      allowHTML: true,
      duration: 0,
      appendTo: this.element,
      onShow: (instance) => {
        tableDiv.classList.add('highlight')
        if (instance.boundClickEvent) return

        instance.boundClickEvent = true
        instance.popper.addEventListener('click', () => this.sendTableToServer(tableDiv))
      },
      onHide: () => {
        tableDiv.classList.remove('highlight')
      }
    })
  }

  sendTableToServer (tableDiv) {
    let json = tableDiv.dataset.tableJson
    const pageNum = tableDiv.dataset.pageNumber
    const cellsInput = this.citationFromTableFormTarget.querySelector('input[name="cells_json"]')
    const pageNumInput = this.citationFromTableFormTarget.querySelector('input[name="citation[page_number]"]')
    const data = JSON.parse(json)

    json = data.map((row, y) => row.map((col, x) => ({ x, y, content: col, colspan: 1 }))).flat()

    pageNumInput.value = pageNum
    cellsInput.value = JSON.stringify(json)
    fire(this.citationFromTableFormTarget, 'submit')
  }
}
