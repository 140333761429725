import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tagCheckbox']

  clearAllTagFilters () {
    this.tagCheckboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    this.submitForm()
  }

  submitForm () {
    this.element.closest('form').submit()
  }
}
