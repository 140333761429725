import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['hideable']

  // Hide the specified element
  hide () {
    this.hideableTarget.style.display = 'none'
  }
}
