import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['versionName', 'errorMessage', 'submitButton']

  static values = {
    collectionVersionList: Array
  }

  connect () {
    super.connect()
    // immediately run validation in case default name is taken
    this.validateUniquenessOfVersionName()
  }

  validateUniquenessOfVersionName () {
    const valid = !this.collectionVersionListValue.includes(this.versionNameTarget.value)

    this.errorMessageTarget.hidden = valid
    this.submitButtonTarget.disabled = !valid
  }
}
