import { fire } from '../../../lib/form_events'
import BaseController from '../../base_controller'

export default class GroupsFilterController extends BaseController {
  static targets = ['modal', 'filterDisplay', 'filterCheckbox']

  clearFilter () {
    this.filterCheckboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    this.closeModalAndSubmit()
  }

  updateFilter () {
    this.closeModalAndSubmit()
  }

  closeModalAndSubmit () {
    this.modalTarget.hidden = true
    fire(this.element.closest('form'), 'submit')
  }
}
