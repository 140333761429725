import BaseController from './base_controller'
import { useClickOutside } from 'stimulus-use'

export default class ToggleModalController extends BaseController {
  static targets = ['modal']

  connect () {
    super.connect()
    useClickOutside(this)
  }

  toggleModal () {
    this.modalTarget.hidden = !this.modalTarget.hidden
  }

  closeModal (event) {
    this.modalTarget.hidden = true
  }
}
