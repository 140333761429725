import { Controller } from '@hotwired/stimulus'
import { fire } from '../../lib/form_events'

export default class extends Controller {
  static targets = [
    'field',
    'form'
  ]

  junctionBarcodeUpdate () {
    if (this.fieldTarget.value === 'Unnamed Junction Barcode') {
      this.fieldTarget.value = null
    }
    fire(this.formTarget, 'submit')
  }
}
