import BaseController from './base_controller'

// Sets all input fields under containers to tabindex="-1" to disable tabbing into them
export default class EventDispatchController extends BaseController {
  static values = {
    eventName: String,
    detail: { type: Object, default: {} }
  }

  dispatchEvent () {
    this.dispatch(this.eventNameValue, { prefix: null, detail: this.detailValue })
  }
}
