import BaseController from './base_controller'

// Sets all input fields under containers to tabindex="-1" to disable tabbing into them
export default class extends BaseController {
  static targets = ['container']
  static values = {
    activateOnConnect: Boolean
  }

  connect () {
    super.connect()
    if (this.activateOnConnectValue) {
      this.disableTabbing()
    }
  }

  disableTabbing () {
    this.containerTargets.forEach(container => {
      container.querySelectorAll('input, button, a, select').forEach(element => {
        element.tabIndex = '-1'
      })
    })
  }

  disableTabbingDelay () {
    setTimeout(() => {
      this.disableTabbing()
    }, 200)
  }
}
