import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'checkBox', 'submitButton'
  ]

  checkReplaceCheckBox () {
    this.submitButtonTarget.disabled = !this.checkBoxTarget.checked
  }
}
