import TippyTooltipController from '../tippy_tooltip_controller.js'

export default class BulkTaggingModalController extends TippyTooltipController {
  initialize () {
    const citationFormSelector = this.generateEditableCitationSelector()
    const tooltipContent = this.triggerTarget.dataset['companyClassifications-BulkTaggingModalContentValue']
    this.triggerTarget.dataset['companyClassifications-BulkTaggingModalContentValue'] =
      tooltipContent.replace('FORM_SELECTOR_PLACEHOLDER', citationFormSelector)
    super.initialize()
  }

  // Create a unique selector for the citation form that contains triggerTarget
  generateEditableCitationSelector () {
    const citationForm = this.triggerTarget.closest('form')
    let containerSelector = ''

    if (citationForm.closest('.extracted-citation-container') !== null) {
      containerSelector = '.extracted-citation-container'
    } else if (citationForm.closest("[data-container='extracting_table_step']") !== null) {
      containerSelector = "[data-container='extracting_table_step']"
    } else if (citationForm.closest('.citation-section-container') !== null) {
      const citableUri = citationForm.closest('.citation-section-container').dataset.citableUri
      containerSelector = ".citation-section-container[data-citable-uri='" + citableUri + "']"
    }

    return containerSelector + " [data-ujs-id='" + this.triggerTarget.dataset.formId + "']"
  }
}
