import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['inputConfirm', 'destroyButton']
  static classes = ['disabled']
  static values = { confirmText: String }

  // Validation for the destroy classification modal -
  // if user does not supply given confirmText in the inputConfirm, then the destroyButton is disabled
  changeDestroyButtonState () {
    const inputConfirmText = this.inputConfirmTarget.value.toLowerCase().trim()
    if (inputConfirmText === this.confirmTextValue) {
      this.destroyButtonTarget.classList.remove(this.disabledClass)
      this.destroyButtonTarget.removeAttribute('tabindex')
    } else {
      this.destroyButtonTarget.classList.add(this.disabledClass)
      this.destroyButtonTarget.setAttribute('tabindex', '-1')
    }
  }
}
