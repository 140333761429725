import BaseController from './base_controller'

export default class ScrollFadeController extends BaseController {
  static targets = ['scrollElement', 'fade']
  static values = {
    adjustForScrollbar: Boolean
  }

  connect () {
    super.connect()

    this.updateScrollFadeIvars()

    // Save to ivar so we can access the same instance and correctly remove the event listener
    this.boundUpdateScrollFadeIvars = this.updateScrollFadeIvars.bind(this)
    window.addEventListener('resize', this.boundUpdateScrollFadeIvars)
  }

  disconnect () {
    window.removeEventListener('resize', this.boundUpdateScrollFadeIvars)
  }

  updateFadeVisibility () {
    // Can't use .hidden because it messes with the scrolling
    if (this.scrollElementTarget.scrollTop >= this.maxScroll - 2) {
      this.fadeTarget.style.opacity = 0
    } else {
      this.fadeTarget.style.opacity = 1
    }
  }

  updateScrollFadeIvars () {
    const initialScroll = this.scrollElementTarget.scrollTop
    this.scrollElementTarget.scroll({ top: 1000000 })
    this.maxScroll = this.scrollElementTarget.scrollTop
    this.scrollElementTarget.scroll({ top: initialScroll })

    if (this.maxScroll === 0) {
      this.fadeTarget.style.display = 'none'
    } else {
      this.fadeTarget.style.display = 'block'
    }

    if (this.adjustForScrollbar()) {
      const horizontalScrollbarWidth = this.scrollElementTarget.offsetHeight - this.scrollElementTarget.clientHeight
      if (horizontalScrollbarWidth > 2) {
        this.fadeTarget.style.bottom = `${horizontalScrollbarWidth - 1}px`
      }
    }

    const verticalScrollbarHeight = this.scrollElementTarget.offsetWidth - this.scrollElementTarget.clientWidth
    if (verticalScrollbarHeight > 2) {
      this.fadeTarget.style.width = `calc(100% - ${this.scrollElementTarget.offsetWidth - this.scrollElementTarget.clientWidth + 2}px)`
    }
  }

  adjustForScrollbar () {
    if (this.hasAdjustForScrollbarValue) {
      return this.adjustForScrollbarValue
    } else {
      return true
    }
  }
}
