import BaseController from './base_controller'

export default class ExpandableFormBasedOnInputController extends BaseController {
  static targets = ['form', 'input']

  connect () {
    super.connect()
    this.changeFormWidth()
  }

  changeFormWidth () {
    const contentWidth = this.inputTarget.scrollWidth + 2 // add 2px for padding
    this.formTarget.style.width = contentWidth + 'px'
  }
}
