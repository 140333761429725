import BaseController from '../../base_controller'

export default class EditModalController extends BaseController {
  static targets = ['formTab', 'gvkeysTab', 'form', 'gvkeys']

  showForm () {
    this.formTabTarget.classList.add('selected')
    this.formTarget.hidden = false

    this.gvkeysTabTarget.classList.remove('selected')
    this.gvkeysTarget.hidden = true
  }

  showGvkeys () {
    this.gvkeysTabTarget.classList.add('selected')
    this.gvkeysTarget.hidden = false

    this.formTabTarget.classList.remove('selected')
    this.formTarget.hidden = true
  }
}
