import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['expandCollapseButton']
  static values = {
    sendEventOnCollapse: { type: Boolean, default: false }
  }

  connect () {
    const rbrId = this.element.dataset.rbrId
    this.rbrLevel = this.element.dataset.rbrLevel
    this.children = this.element.parentElement.querySelectorAll("[data-rbr-parent-id='" + rbrId + "']")
    super.connect()
  }

  toggleRbr () {
    if (this.children.length === 0) { return }

    const isExpanded = this.expandCollapseButtonTarget.classList.contains('bucket-is-expanded')

    if (isExpanded) {
      this.collapseRbr()
    } else {
      this.expandRbr()
    }
  }

  collapseRbr () {
    if (!this.expandCollapseButtonTarget.classList.contains('bucket-is-expanded')) { return }

    this.expandCollapseButtonTarget.classList.remove('bucket-is-expanded')
    this.expandCollapseButtonTarget.dataset.expandState = false

    // TODO: Implement a method here instead of using old method
    // eslint-disable-next-line no-undef
    collapse_children(this.element, 'rbr')

    if (this.rbrLevel === '1') {
      this.dispatch('collapsedRootRbr')
    } else if (this.rbrLevel === '2') {
      this.dispatch('collapsedRbr')
    }
  }

  expandRbr () {
    if (this.expandCollapseButtonTarget.classList.contains('bucket-is-expanded')) { return }

    this.expandCollapseButtonTarget.classList.add('bucket-is-expanded')
    this.expandCollapseButtonTarget.dataset.expandState = true

    // TODO: Implement a method here instead of using old method
    // eslint-disable-next-line no-undef
    expand_children(this.element, 'rbr')

    this.dispatch('expandedRbr')
  }
}
