import { Controller } from '@hotwired/stimulus'

// This controller is used to automatically open anchor tags that
// it is connected to.
export default class extends Controller {
  connect () {
    this.openAnchorTag()
  }

  openAnchorTag () {
    this.element.click()
  }
}
