import BaseController from '../../base_controller'

// This controller sets the width of Corporate Action Cards to the widest card width.
export default class extends BaseController {
  static targets = ['actionCardContent']

  connect () {
    super.connect()
    this.setWidthToWidest()
  }

  disconnect () {
    super.disconnect()
  }

  setWidthToWidest () {
    let widestCard = 0

    // find widest width
    this.actionCardContentTargets.forEach(actionCard => {
      if (actionCard.offsetWidth > widestCard) {
        widestCard = actionCard.offsetWidth
      }
    })

    // set all width to widest
    this.actionCardContentTargets.forEach(actionCard => {
      actionCard.style.width = widestCard + 'px'
    })
  }
}
