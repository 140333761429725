import BaseController from '../base_controller'
import tippy from 'tippy.js'
import { fire } from '../../lib/form_events'

export default class InteractiveDocumentController extends BaseController {
  static targets = ['iframe', 'citationFromTableForm', 'citationFromTableCellsInput']

  static values = { documentSanitized: Boolean }

  connect () {
    super.connect()

    this.iframeTarget.onload = () => {
      // Do nothing if iframe is inaccessible
      if (!this.documentSanitizedValue) return

      const tables = Array.from(this.iframeTarget.contentWindow.document.getElementsByTagName('table'))

      const tooltipContent = `
        <div class="lexicon-document-copy-to-citation-button">
          Extract Table
        </div>
      `

      this.tippyInstance =
        tippy(tables, {
          content: tooltipContent,
          placement: 'bottom-end',
          interactive: true,
          trigger: 'click',
          allowHTML: true,
          appendTo: this.iframeTarget.contentWindow.document.body,
          duration: 0,
          controllerInstance: this, // Add ref to controller to access in onShow
          onShow (instance) {
            const table = instance.reference
            table.classList.add('lexicon-document-highlight-table-to-extract')
            if (this.boundClickEvent) return

            this.boundClickEvent = true
            const data = { tippyInstance: instance, controllerInstance: this.controllerInstance }
            instance.popper.addEventListener('click', this.controllerInstance.sendTableToServer.bind(data))
          },
          onHide (instance) {
            const table = instance.reference
            table.classList.remove('lexicon-document-highlight-table-to-extract')
          }
        })
    }
  }

  sendTableToServer () {
    const table = this.tippyInstance.reference
    let y = 0
    const cells = []

    Array.from(table.getElementsByTagName('tr')).forEach(row => {
      let x = 0
      Array.from(row.querySelectorAll('th, td')).forEach(cell => {
        cells.push({ x, y, content: cell.textContent, colspan: cell.colSpan })

        x++
      })
      y++
    })
    this.controllerInstance.citationFromTableCellsInputTarget.value = JSON.stringify(cells)
    fire(this.controllerInstance.citationFromTableFormTarget, 'submit')
  }
}
