import BaseController from './base_controller'

export default class ModalController extends BaseController {
  static targets = ['modal', 'modalMask']
  static classes = ['hidden', 'visible']

  /**
   * Slide in side panel modal from the right side
   */
  show () {
    this.modalTarget.classList.remove(this.hiddenClass)
    this.modalMaskTarget.classList.remove(this.hiddenClass)

    this.modalTarget.classList.add(this.visibleClass)
    this.modalMaskTarget.classList.add(this.visibleClass)
  }

  /**
   * Slide out side panel modal to the right side
   * when someone presses `Escape` or clicks on the modal mask
   */
  hide (event) {
    const escapeEvent = event.type === 'keyup' && event.key === 'Escape'
    const clickEvent = event.type === 'click'
    const modalVisible = this.modalTarget.classList.contains(this.visibleClass)

    if (modalVisible && (escapeEvent || clickEvent)) {
      this.modalTarget.classList.remove(this.visibleClass)
      this.modalTarget.classList.add(this.hiddenClass)

      this.modalMaskTarget.classList.remove(this.visibleClass)
      this.modalMaskTarget.classList.add(this.hiddenClass)
    }
  }
}
