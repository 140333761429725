import BaseController from '../../base_controller'

export default class ProjectStatusFilterController extends BaseController {
  static targets = ['projectType']

  updateProjectStatus (event) {
    const projectTypeValue = event.target.dataset.projectTypeTabValue

    this.projectTypeTarget.value = projectTypeValue
  }
}
