import moment from 'moment'
import BaseController from './base_controller'

const earliestAllowedDateString = '1950-01-01'
const latestAllowedDateString = '2999-12-31'
const dateTooEarlyErrorMsg = 'Date should be after 12/31/1949 and before 12/21/2999'
const dateInvalidErrorMsg = 'End date cannot be earlier than start date'
const encompassFiscalYearEndErrorMsg =
    'This company has a classification FYE date that falls within the no document date range'

export default class extends BaseController {
  static targets = ['startDate', 'endDate', 'errorMsg', 'formSubmit']
  static values = {
    fiscalYearEndDates: Array,
    validationMethod: { type: String, default: 'all' }
  }

  connect () {
    super.connect()

    this.validateDateRange()
  }

  validateDateRange () {
    const startDateString = this.startDateTarget.value
    const endDateString = this.endDateTarget.value

    const startDateOutOfRange = this.outsideSupportedRange(startDateString)
    const endDateOutOfRange = this.outsideSupportedRange(endDateString)

    // both dates have value
    if (!!startDateString && !!endDateString) {
      if (startDateOutOfRange) {
        this.resetMinimumEndDate()
      }

      if (endDateOutOfRange) {
        this.resetMaximumStartDate()
      }

      if (this.rangeEncompassesFiscalYearEnd(startDateString, endDateString)) {
        this.displayErrorMsg(encompassFiscalYearEndErrorMsg)
        this.disableFormSubmit()
      } else if (startDateOutOfRange || endDateOutOfRange) {
        this.displayErrorMsg(dateTooEarlyErrorMsg)
        this.disableFormSubmit()
      } else if (this.invalidRange(startDateString, endDateString)) {
        this.resetMaximumStartDate()
        this.resetMinimumEndDate()

        this.displayErrorMsg(dateInvalidErrorMsg)
        this.disableFormSubmit()
      } else {
        this.updateMaximumStartDate()
        this.updateMinimumEndDate()

        this.clearErrorMsg()
        this.enableFormSubmit()
      }

    // only start date has value
    } else if (startDateString) {
      if (startDateOutOfRange) {
        this.displayErrorMsg(dateTooEarlyErrorMsg)
        this.resetMinimumEndDate()
      } else {
        this.updateMinimumEndDate()
        this.resetMaximumStartDate()

        this.clearErrorMsg()
      }

      if (this.validationMethodValue === 'all') {
        this.disableFormSubmit()
      }

    // only end date has value
    } else if (endDateString) {
      if (endDateOutOfRange) {
        this.displayErrorMsg(dateTooEarlyErrorMsg)
        this.resetMaximumStartDate()
      } else {
        this.updateMaximumStartDate()
        this.resetMinimumEndDate()

        this.clearErrorMsg()
      }

      if (this.validationMethodValue === 'all') {
        this.disableFormSubmit()
      }

    // neither has value
    } else {
      this.resetMaximumStartDate()
      this.resetMinimumEndDate()

      this.clearErrorMsg()

      if (this.validationMethodValue === 'all') {
        this.disableFormSubmit()
      }
    }
  }

  // Like validateDateRange but only activates form if valid
  enableSubmitIfValid () {
    let valid = false

    if (this.validationMethodValue === 'all') {
      valid = this.allFieldsValid()
    } else if (this.validationMethodValue === 'any') {
      valid = this.anyFieldsValid()
    }

    if (valid) {
      this.updateMaximumStartDate()
      this.updateMinimumEndDate()

      this.clearErrorMsg()
      this.enableFormSubmit()
    } else {
      this.disableFormSubmit()
    }
  }

  // Returns true if all fields are present and have their
  // validations pass, used for enableSubmitIfValid
  allFieldsValid () {
    const startDateString = this.startDateTarget.value
    const endDateString = this.endDateTarget.value

    return !!startDateString &&
        !!endDateString &&
        !this.outsideSupportedRange(startDateString) &&
        !this.outsideSupportedRange(endDateString) &&
        !this.rangeEncompassesFiscalYearEnd(startDateString, endDateString) &&
        !this.invalidRange(startDateString, endDateString)
  }

  // Returns true if any of the filled in fields are valid.
  // If none of the fields are filled in, that is valid as well.
  anyFieldsValid () {
    const startDateString = this.startDateTarget.value
    const endDateString = this.endDateTarget.value

    const startDatePresent = !!startDateString
    const endDatePresent = !!endDateString

    // If both dates are empty, this is a valid set of inputs.
    if (!startDatePresent && !endDatePresent) {
      return true
    }

    // If only the start is present, just validate startDateString
    if (startDatePresent && !endDatePresent) {
      const date = moment(startDateString)
      const earliestAllowedDate = moment(earliestAllowedDateString)
      return date.isValid() && date.isAfter(earliestAllowedDate)
    }

    // If only the end is present, just validate endDateString
    if (!startDatePresent && endDatePresent) {
      const date = moment(endDateString)
      const latestAllowedDate = moment(latestAllowedDateString)
      return date.isValid() && date.isBefore(latestAllowedDate)
    }

    // If both are present, validate both.
    if (startDatePresent && endDatePresent) {
      return !!startDateString &&
          !!endDateString &&
          !this.outsideSupportedRange(startDateString) &&
          !this.outsideSupportedRange(endDateString) &&
          !this.rangeEncompassesFiscalYearEnd(startDateString, endDateString) &&
          !this.invalidRange(startDateString, endDateString)
    }
  }

  displayErrorMsg (msg) {
    if (!this.hasErrorMsgTarget) {
      return
    }

    this.errorMsgTarget.innerHTML = msg
    this.errorMsgTarget.hidden = false
  }

  clearErrorMsg () {
    if (!this.hasErrorMsgTarget) {
      return
    }

    this.errorMsgTarget.innerHTML = ''
    this.errorMsgTarget.hidden = true
  }

  disableFormSubmit () {
    if (!this.hasFormSubmitTarget) {
      return
    }

    this.formSubmitTarget.disabled = true
  }

  enableFormSubmit () {
    if (!this.hasFormSubmitTarget) {
      return
    }

    this.formSubmitTarget.disabled = false
  }

  outsideSupportedRange (dateString) {
    const date = moment(dateString)
    const earliestAllowedDate = moment(earliestAllowedDateString)
    const latestAllowedDate = moment(latestAllowedDateString)

    return !date.isValid() || date.isBefore(earliestAllowedDate) || date.isAfter(latestAllowedDate)
  }

  rangeEncompassesFiscalYearEnd (startDateString, endDateString) {
    if (!this.hasFiscalYearEndDatesValue) {
      return false
    }

    const startDate = moment(startDateString)
    const endDate = moment(endDateString)

    return this.fiscalYearEndDatesValue.find(function (fiscalYearEndDateString) {
      const fiscalYearEnd = moment(fiscalYearEndDateString)

      return fiscalYearEnd.isAfter(startDate) && fiscalYearEnd.isBefore(endDate)
    })
  }

  invalidRange (startDateString, endDateString) {
    const startDate = moment(startDateString)
    const endDate = moment(endDateString)

    return endDate.isBefore(startDate)
  }

  updateMaximumStartDate () {
    this.startDateTarget.max = this.endDateTarget.value
  }

  resetMaximumStartDate () {
    this.startDateTarget.max = ''
  }

  updateMinimumEndDate () {
    this.endDateTarget.min = this.startDateTarget.value
  }

  resetMinimumEndDate () {
    this.endDateTarget.min = earliestAllowedDateString
  }
}
