import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['initiallyVisibleElement', 'initiallyHiddenElement', 'indicator']
  static values = {
    indicatorElementsVisibleClass: { type: String, default: 'elements-visible' },
    indicatorElementsHiddenClass: { type: String, default: 'elements-hidden' }
  }

  toggleVisibility () {
    if ((this.hasInitiallyVisibleElementTarget && this.initiallyVisibleElementTarget.hidden === false) ||
        (this.hasInitiallyHiddenElementTarget && this.initiallyHiddenElementTarget.hidden === true)) {
      this.initiallyVisibleElementTargets.forEach(element => {
        element.hidden = true
      })

      this.initiallyHiddenElementTargets.forEach(element => {
        element.hidden = false
      })

      if (this.hasIndicatorTarget) {
        this.indicatorTarget.classList.add(this.indicatorElementsVisibleClassValue)
        this.indicatorTarget.classList.remove(this.indicatorElementsHiddenClassValue)
      }
    } else {
      this.initiallyVisibleElementTargets.forEach(element => {
        element.hidden = false
      })

      this.initiallyHiddenElementTargets.forEach(element => {
        element.hidden = true
      })

      if (this.hasIndicatorTarget) {
        this.indicatorTarget.classList.remove(this.indicatorElementsVisibleClassValue)
        this.indicatorTarget.classList.add(this.indicatorElementsHiddenClassValue)
      }
    }
  }
}
