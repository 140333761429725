import { Controller } from '@hotwired/stimulus'

export default class NotificationsModalController extends Controller {
  connect () {
    document.addEventListener('mousedown', this.closeModal.bind(this))
  }

  disconnect () {
    document.removeEventListener('mousedown', this.closeModal.bind(this))
  }

  toggleModal () {
    const modal = document.getElementById('notifications_modal')
    modal.hidden = !modal.hidden

    // Preemptively remove the notifications count since opening the notifications modal will trigger
    // a GET request to UserActivites#index which marks all notifications as read.
    const notificationsCount = document.querySelector("[data-ujs-id='notifications_count_container']")
    notificationsCount.innerHTML = ''
  }

  closeModal (event) {
    const modal = document.getElementById('notifications_modal')
    const notificationsIcon = document.getElementById('notifications_icon')

    if (modal.contains(event.target) || notificationsIcon.contains(event.target)) {
      return
    }

    modal.hidden = true
  }
}
