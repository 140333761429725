import BaseController from '../base_controller'

// Enable form submit button when all inputs are validated
export default class extends BaseController {
  static targets = ['formSubmit', 'selectInput', 'statusSelectInput', 'actionTypeSelectInput',
    'companySection', 'companyHiddenInput', 'dateInput']

  connect () {
    super.connect()

    this.validateInputs()
  }

  disconnect () {
    super.disconnect()
  }

  validateInputs () {
    this.enableFormSubmit()

    // Disable form submit if any below is invalid
    this.validateSelectInputs()
    this.validateCompanyHiddenInputs()
    this.validateDateInput()
  }

  validateSelectInputs () {
    if (this.hasSelectInputTarget) {
      for (const select of this.selectInputTargets) {
        if (this.validateSelectInput(select)) {
          this.disableFormSubmit()
          break
        }
      }
    }
  }

  // Returns true if given select input is not empty. Also returns true if it's the action type input
  //   and the form's status input is set to 'active'
  validateSelectInput (select) {
    if (select === this.actionTypeSelectInputTarget && this.statusSelectInputTarget.value === 'active') {
      return false
    }

    return select.value === ''
  }

  // For companyHiddenInputs, it is valid if at least one has value
  // Company input may not always present
  validateCompanyHiddenInputs () {
    let hasValueCount = 0

    if (this.companySectionTarget.hidden === true) return

    for (const companyInput of this.companyHiddenInputTargets) {
      if (companyInput.value !== '') {
        hasValueCount++
        break
      }
    }

    if (hasValueCount === 0) this.disableFormSubmit()
  }

  validateDateInput () {
    if (this.hasDateInputTarget && this.dateInputTarget.value === '') {
      this.disableFormSubmit()
    }
  }

  disableFormSubmit () {
    this.formSubmitTarget.disabled = true
  }

  enableFormSubmit () {
    this.formSubmitTarget.disabled = false
  }
}
