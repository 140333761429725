import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['requiredField', 'submitButton']

  attributeChanged () {
    let allFilled = true
    this.requiredFieldTargets.forEach(field => {
      allFilled = allFilled && field.value !== ''
    })

    if (allFilled) {
      this.submitButtonTarget.removeAttribute('disabled')
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'true')
    }
  }
}
