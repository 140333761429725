import BaseController from './base_controller'

// Stimulus controller for selecting a tab and add/remove tab content "style" attribute
// For the tab and content to work correctly, tab element must have "data-tab-name" attribute
// set to content element's id
//
// Example (not the complete setup):
// <div data-tab-select-target="tab"
//      data-tab-name="tab_1">Tab 1</div>
// <div data-tab-select-target="tab"
//      data-tab-name="tab_2">Tab 2</div>
//
// <div id="tab_1" class="initial-display">Content for Tab 1</div>
// <div id="tab_2" style="display: none">Content for Tab 2</div>
export default class extends BaseController {
  static targets = ['tab', 'content']
  static classes = ['selected']

  selectTab (event) {
    const currentTab = event.currentTarget
    const tabs = this.tabTargets

    this.showSelectedTabContent(currentTab)

    tabs.forEach(tab => {
      if (tab === currentTab) {
        tab.classList.add(this.selectedClass)
      } else {
        tab.classList.remove(this.selectedClass)
      }
    })
  }

  showSelectedTabContent (currentTab) {
    const tabName = currentTab.dataset.tabName
    const contents = this.contentTargets

    contents.forEach(content => {
      if (content.id === tabName) {
        content.removeAttribute('style')
      } else {
        content.style.display = 'none'
      }
    })
  }
}
