import BaseController from '../base_controller'

export default class CellClickAndDragController extends BaseController {
  static targets = [
    'hiddenCellIdsInput', 'table', 'populateValuesButton', 'populateValuesButtonDisabled', 'textAndNumberCallout'
  ]

  connect () {
    document.addEventListener('mouseup', this.finishSelectionOnMouseUp.bind(this))
    document.addEventListener('click', this.clearSelection.bind(this))
    super.connect()
  }

  disconnect () {
    if (document == null) return // Quash an error in tests

    document.removeEventListener('mouseup', this.finishSelectionOnMouseUp.bind(this))
    document.removeEventListener('click', this.clearSelection.bind(this))
    super.disconnect()
  }

  // When clicking down on a cell, set it as the anchor cell and remove the old selection.
  mouseDownCell (event) {
    if (!this.#eventIsLeftClick(event)) return

    event.preventDefault()
    event.stopPropagation()

    if (event.currentTarget.classList.contains('selection-highlighted')) {
      this.#clearCell()
      return
    }

    // Remove all highlights
    this.tableTarget.querySelectorAll('.selection-highlighted').forEach(function (highlightedCell) {
      highlightedCell.classList.remove('selection-highlighted')
    })

    // Hide the callout
    this.textAndNumberCalloutTarget.style.display = 'none'

    this.anchorCell = event.currentTarget
    this.anchorCell.classList.add('selection-highlighted')
  }

  // When the mouse enters a cell, set the destination cell and update the displayed highlights
  mouseEnterCell (event) {
    this.hoveredCell = event.target

    if (this.anchorCell == null) return

    // Remove all highlights
    this.tableTarget.querySelectorAll('.selection-highlighted').forEach(function (highlightedCell) {
      highlightedCell.classList.remove('selection-highlighted')
    })

    this.#updateDestinationCell()
    this.#updateSelectedCells()

    // Highlight cells
    this.selectedCells.forEach(function (selectedCell) {
      selectedCell.classList.add('selection-highlighted')
    })
  }

  // On mouse up, finish selection and update hidden field
  finishSelectionOnMouseUp (event) {
    if (!this.#eventIsLeftClick(event)) return
    if (this.anchorCell == null) return

    this.#updateSelectedCells()
    this.#updateHiddenSelectedCellsField()

    // Show the button
    this.populateValuesButtonTarget.style.display = 'inline-block'
    this.populateValuesButtonDisabledTarget.style.display = 'none'

    // Show the callout
    if (this.#showCallout()) {
      this.textAndNumberCalloutTarget.style.display = 'flex'
    }

    this.destinationCell = null
    this.hoveredCell = null
    this.anchorCell = null
  }

  // When clicking outside the table, clear the selection
  clearSelection (event) {
    if (this.selectedCells == null) return
    if (event.srcElement.classList.contains('js-populate-values-link')) return
    if (event.target.closest('table') === this.tableTarget) return

    this.destinationCell = null
    this.hoveredCell = null
    this.anchorCell = null
    this.selectedCells = null
    this.#updateHiddenSelectedCellsField()

    // Hide the button
    this.populateValuesButtonTarget.style.display = 'none'
    this.populateValuesButtonDisabledTarget.style.display = 'inline-block'

    // Hide the callout
    this.textAndNumberCalloutTarget.style.display = 'none'

    // Remove all highlights
    this.tableTarget.querySelectorAll('.selection-highlighted').forEach(function (highlightedCell) {
      highlightedCell.classList.remove('selection-highlighted')
    })
  }

  // Select text in a cell
  selectCellText (event) {
    let copyContent = event.target.closest('td').dataset.cellCopyValue

    if (!copyContent) copyContent = event.target.textContent.trim()

    navigator.clipboard.writeText(copyContent)
  }

  #clearCell () {
    if (this.selectedCells == null) return

    this.selectedCells.splice(this.selectedCells.indexOf(this.hoveredCell), 1)
    this.#updateHiddenSelectedCellsField()

    // Update the callout visibility
    if (this.#showCallout()) {
      this.textAndNumberCalloutTarget.style.display = 'flex'
    } else {
      this.textAndNumberCalloutTarget.style.display = 'none'
    }

    if (this.selectedCells.length === 0) {
      // Hide the button
      this.populateValuesButtonTarget.style.display = 'none'
      this.populateValuesButtonDisabledTarget.style.display = 'inline-block'
    }

    if (this.hoveredCell) {
      this.hoveredCell.classList.remove('selection-highlighted')
    }
  }

  // Set the "destination" cell based on the currently hovered cell.
  // The destination should be the closest cell to the hovered cell that is in the same row/column as the anchor cell
  #updateDestinationCell () {
    const anchorCellX = parseInt(this.anchorCell.dataset.positionX)
    const anchorCellY = parseInt(this.anchorCell.dataset.positionY)
    const hoveredCellX = parseInt(this.hoveredCell.dataset.positionX)
    const hoveredCellY = parseInt(this.hoveredCell.dataset.positionY)

    const xDistance = Math.abs(anchorCellX - hoveredCellX)
    const yDistance = Math.abs(anchorCellY - hoveredCellY)

    let destinationX = 0
    let destinationY = 0
    if (xDistance < yDistance) {
      // Closer on x axis
      destinationX = anchorCellX
      destinationY = hoveredCellY
    } else {
      // Closer on y axis
      destinationX = hoveredCellX
      destinationY = anchorCellY
    }

    this.destinationCell = this.tableTarget.querySelector("[data-position-x='" + destinationX + "'][data-position-y='" + destinationY + "']")
  }

  // Set the selected cells to be the cells between the anchor and destination
  #updateSelectedCells () {
    this.selectedCells = []

    if (this.anchorCell === this.destinationCell || this.destinationCell == null) {
      this.selectedCells.push(this.anchorCell)
      return
    }

    const anchorCellX = parseInt(this.anchorCell.dataset.positionX)
    const anchorCellY = parseInt(this.anchorCell.dataset.positionY)
    const destinationCellX = parseInt(this.destinationCell.dataset.positionX)
    const destinationCellY = parseInt(this.destinationCell.dataset.positionY)
    this.tableTarget.querySelectorAll('td').forEach(cell => {
      const cellX = parseInt(cell.dataset.positionX)
      const cellY = parseInt(cell.dataset.positionY)

      // Cell is not in range
      if (cellX !== anchorCellX && cellY !== anchorCellY) return

      // Check if the cell is between the anchor cell and the destination cell. Return if it isn't
      if (destinationCellX === anchorCellX && cellX === anchorCellX) {
        // Cell is on a column
        if (destinationCellY > anchorCellY && (cellY > destinationCellY || cellY < anchorCellY)) return
        if (anchorCellY > destinationCellY && (cellY > anchorCellY || cellY < destinationCellY)) return
      } else if (destinationCellY === anchorCellY && cellY === anchorCellY) {
        // Cell is on a row
        if (destinationCellX > anchorCellX && (cellX > destinationCellX || cellX < anchorCellX)) return
        if (anchorCellX > destinationCellX && (cellX > anchorCellX || cellX < destinationCellX)) return
      } else {
        return
      }

      this.selectedCells.push(cell)
    })
  }

  // Update the hidden field with the selected cell ids
  #updateHiddenSelectedCellsField () {
    if (this.selectedCells == null) {
      this.hiddenCellIdsInputTarget.value = null
      return
    }

    this.hiddenCellIdsInputTarget.value = JSON.stringify(this.selectedCells.map(cell => cell.dataset.citationTableCellId))
  }

  // Returns true if the event is a left click event
  #eventIsLeftClick (event) {
    if ('which' in event) {
      return event.which === 1
    } else if ('button' in event) {
      return event.button === 1
    }
  }

  #showCallout () {
    let selectedNumericCellsCount = 0
    let selectedTextCellsCount = 0

    this.selectedCells.forEach(cell => {
      if (cell.dataset.cellDataType === 'numeric') {
        selectedNumericCellsCount++
      } else if (cell.dataset.cellDataType === 'text') {
        selectedTextCellsCount++
      }
    })

    return selectedNumericCellsCount > 0 && selectedTextCellsCount > 0
  }
}
