import { useClickOutside } from 'stimulus-use'
import BaseController from './base_controller'
import { fire } from '../lib/form_events'

export default class ProjectFiltersController extends BaseController {
  static targets = [
    'searchTerm',
    'searchTermForDisplay',
    'projectSourceSelectField',
    'projectOptionsSelectField',
    'noDocCheckbox',
    'filterModal',
    'projectClassificationStateField',
    'projectClassificationStateButton',
    'projectOptionsSubmitButton',
    'clearFiltersButton'
  ]

  static values = {
    optionsJson: Object,
    useClickOutside: Boolean
  }

  connect () {
    super.connect()

    // wire up useClickOutside listener if useClickOutsideValue is set to true
    if (this.useClickOutsideValue) {
      useClickOutside(this)
    }

    if (this.hasClearFiltersButtonTarget && this.searchTermTarget.value === '') {
      this.clearFiltersButtonTarget.disabled = true
    }

    if (this.hasProjectOptionsSelectFieldTarget && this.hasProjectOptionsSubmitButtonTarget) {
      if (this.projectOptionsSelectFieldTarget.value === '') {
        this.projectOptionsSubmitButtonTarget.disabled = true
      }
    }
  }

  onClickOutside () {
    this.closeModal()
  }

  closeModal () {
    this.filterModalTarget.hidden = true
  }

  toggleModal () {
    this.filterModalTarget.hidden = !this.filterModalTarget.hidden
  }

  setProjectOptions () {
    const possibleOptions = this.optionsJsonValue
    const projectOption = this.projectSourceSelectFieldTarget.value

    if (projectOption === '') {
      this.searchTermForDisplayTarget.innerHTML = 'Any'
      this.projectOptionsSelectFieldTarget.innerHTML = ''
      this.projectOptionsSelectFieldTarget.disabled = true

      // Disable submit button if project options has no value
      if (this.hasProjectOptionsSubmitButtonTarget) {
        toggleSubmitButton(this.projectOptionsSelectFieldTarget, this.projectOptionsSubmitButtonTarget)
      }
      return
    }

    this.projectOptionsSelectFieldTarget.innerHTML = possibleOptions[projectOption]
    this.projectOptionsSelectFieldTarget.disabled = false

    // If project select is valid can submit form
    if (this.hasProjectOptionsSubmitButtonTarget) {
      toggleSubmitButton(this.projectOptionsSelectFieldTarget, this.projectOptionsSubmitButtonTarget)
    }

    const searchDisplay = this.projectSourceSelectFieldTarget.querySelector('option[value="' + projectOption + '"]').innerHTML
    this.searchTermForDisplayTarget.innerHTML = searchDisplay
  }

  setHiddenFieldValueAndSubmit () {
    this.searchTermTarget.value = this.projectOptionsSelectFieldTarget.value

    if (this.hasProjectOptionsSubmitButtonTarget) {
      toggleSubmitButton(this.projectOptionsSelectFieldTarget, this.projectOptionsSubmitButtonTarget)
    } else {
      if (this.searchTermTarget.value === '') return

      this.filterModalTarget.hidden = true
      this.submitForm()
    }
  }

  enableSumbitButton () {
    const projectSource = this.projectSourceSelectFieldTarget.value
    const projectOption = this.projectOptionsSelectFieldTarget.value
    const submitButton = this.projectOptionsSubmitButtonTarget

    if (submitButton && projectSource && projectOption) {
      submitButton.disabled = false
    }
  }

  submitButton () {
    this.filterModalTarget.hidden = true

    // Disabled button until page reloads to stop resubmit on longer response times
    this.projectOptionsSubmitButtonTarget.disabled = true

    this.submitForm()
  }

  setHiddenClassificationState (event) {
    // Clear The Active Style
    const previousState = this.projectClassificationStateButtonTargets.find((dom) => dom.classList.contains('selected'))
    previousState.classList.remove('selected')
    // Apply Active Style To New Element
    event.target.classList.add('selected')
    if (this.projectClassificationStateFieldTarget.value !== event.target.dataset.value) {
      this.enableSumbitButton()
    }
    // Update Hidden Value
    this.projectClassificationStateFieldTarget.value = event.target.dataset.value
  }

  clearHiddenFieldValueAndSubmit () {
    this.searchTermTarget.value = ''
    this.clearFiltersButtonTarget.disabled = true
    this.submitForm()
  }

  showNoDocCheckbox (event) {
    if (this.hasNoDocCheckboxTarget && event.target.dataset.value === 'with_classifications') {
      this.noDocCheckboxTarget.hidden = true
    } else {
      this.noDocCheckboxTarget.hidden = false
    }
  }

  submitForm () {
    const form = this.element.closest('form')
    if (form.dataset.remote) {
      fire(form, 'submit')
    } else {
      form.submit()
    }
  }
}

function toggleSubmitButton (selectField, submitButton) {
  submitButton.disabled = selectField.value === ''
}
