import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['classificationName', 'documentName', 'documentType']

  documentTypeChanged () {
    const documentType = this.documentTypeTarget.selectedOptions[0].value

    if (documentType === 'Other') {
      this.documentNameTarget.disabled = false
      this.documentNameTarget.value = ''
    } else {
      this.documentNameTarget.disabled = true

      const classificationName = this.classificationNameTarget.value
      const documentType = this.documentTypeTarget.value
      this.documentNameTarget.value = classificationNameFormatter(classificationName, documentType)
    }
  }

  classificationNameChanged () {
    const classificationName = this.classificationNameTarget.value
    const documentType = this.documentTypeTarget.value
    this.documentNameTarget.value = classificationNameFormatter(classificationName, documentType)
  }

  enableDocumentNameField () {
    this.documentNameTarget.disabled = false
  }
}

function classificationNameFormatter (classificationName, documentType) {
  if (classificationName.trim() === '') {
    return `${documentType}`
  } else {
    return `${classificationName.trim()} ${documentType}`
  }
}
