import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['hiddenInput', 'select']
  static values = {
    originalText: String,
    newText: String,
    triggerText: String // should be set to the value for the 'Create Baseline Classification' selection
  }

  // Update hidden baseline_classification input value to true
  // if 'Create Baseline Classification' were selected
  updateHiddenInputValue () {
    const selecctedValue = this.selectTarget.value

    if (selecctedValue === this.triggerTextValue) {
      this.hiddenInputTarget.value = this.newTextValue
    } else {
      this.hiddenInputTarget.value = this.originalTextValue
    }
  }
}
