import BaseController from '../base_controller'

// Add/remove company row from the form and hide/show Company section
export default class extends BaseController {
  static targets = [
    'actionType',
    'companySection',
    'companyRow',
    'companyRowsContainer',
    'addCompanyRow',
    'newCompanyTemplate',
    'validationCntrl' // target the validation controller so can be trigger within this controller
  ]

  static values = {
    actionTypesRequireCompany: Array
  }

  toggleHideCompanySection () {
    const actionType = this.actionTypeTarget.value
    const companySection = this.companySectionTarget

    if (this.actionTypesRequireCompanyValue.includes(actionType)) {
      companySection.hidden = false
    } else {
      companySection.hidden = true
    }
  }

  addCompanyRow () {
    this.companyRowsContainerTarget.insertAdjacentHTML('beforeend', this.newCompanyTemplateTarget.innerHTML)

    if (this.numCompanyRows() > 10) this.hideAddCompanyRow()
  }

  removeCompanyRow (event) {
    event.stopPropagation()

    const currentCompanyRow = event.currentTarget.closest("[data-corporate-actions--company-input-target='companyRow']")

    if (this.nonTemplateCompanyRowTargets().length === 1) {
      this.clearLastCompanyRow()
    } else {
      currentCompanyRow.remove()
    }

    if (this.numCompanyRows() <= 10) this.showAddCompanyRow()

    // When a row is delete, need to trigger validation to check if rest of inputs are still valid
    this.validateInputs()
  }

  validateInputs () {
    const validationCntrl = this.application.getControllerForElementAndIdentifier(this.validationCntrlTarget, 'corporate-actions--form-validation')
    validationCntrl.validateInputs()
  }

  clearLastCompanyRow () {
    const lastCompanyRow = this.nonTemplateCompanyRowTargets()[0]

    lastCompanyRow.querySelectorAll('input').forEach(input => {
      input.value = ''
      input.dataset.currentCompanyName = ''
    })
  }

  nonTemplateCompanyRowTargets () {
    return this.companyRowTargets.filter((target) => target.parentElement !== this.newCompanyTemplateTarget)
  }

  numCompanyRows () {
    return this.companyRowTargets.length
  }

  showAddCompanyRow () {
    this.addCompanyRowTarget.hidden = false
  }

  hideAddCompanyRow () {
    this.addCompanyRowTarget.hidden = true
  }
}
