import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['replaceable']

  // Update the target with the new content
  replace () {
    this.replaceableTarget.innerHTML = event.target.innerHTML
  }
}
