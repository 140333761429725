import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

export default class SummaryBarcodeHighlightController extends Controller {
  static targets = ['summaryBarcode']
  connect () {
    super.connect()
    this.highlight = debounce(this.highlight, 500)
  }

  highlight () {
    if (this.summaryBarcodeTarget.dataset.hovered === 'true') {
      this.clearSbHighlights()
      document.querySelectorAll("[data-resource-gid='" + this.summaryBarcodeTarget.dataset.resourceGid + "']")
        .forEach(function (element) {
          // The line below is a hack that ensures the animation re-runs when the class is reapplied to an element
          //   already with the class
          // eslint-disable-next-line no-void
          void element.offsetWidth
          element.classList.add('highlighted-sb')
        })
    }
  }

  clearSbHighlights () {
    document.querySelectorAll('.highlighted-sb')
      .forEach(function (element) { element.classList.remove('highlighted-sb') })
  }

  addHoveredFlag () {
    this.summaryBarcodeTarget.dataset.hovered = true
  }

  removeHoveredFlag () {
    this.summaryBarcodeTarget.dataset.hovered = false
  }
}
