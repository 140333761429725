import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['form']

  static values = {
    interval: { type: Number, default: 2000 }
  }

  connect () {
    this.intervalId = setInterval(
      () => this.submit(),
      this.intervalValue
    )
  }

  // Make sure to clear the interval for this polling
  // instance so that we don't keep trying to submit
  // a form that has been removed from the page.
  disconnect () {
    clearInterval(this.intervalId)
  }

  submit () {
    this.formTarget.requestSubmit()
  }
}
