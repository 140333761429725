import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['pollingForm']
  static values = {
    pollingInterval: { type: Number, default: 3000 }
  }

  timeout = null

  connect () {
    // if uploading a document refresh view periodically
    if (this.element.querySelector('.spinner')) {
      this.timeout = setTimeout(async () => {
        this.pollingFormTarget.requestSubmit()
        this.timeout = null
      }, this.pollingIntervalValue)
    }
  }

  disconnect () {
    clearTimeout(this.timeout)
  }
}
