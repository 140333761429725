import moment from 'moment'
import BaseController from '../../base_controller'

export default class FixedDateRangeController extends BaseController {
  static targets = ['startDate', 'endDate']

  updateStartDate () {
    const endDate = moment(this.endDateTarget.value)
    const startDate = endDate.subtract(1, 'year').add(1, 'day')
    this.startDateTarget.value = startDate.format('YYYY-MM-DD')
  }
}
