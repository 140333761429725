import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'

export default class RssSyncGraphController extends Controller {
  static GRAPH_FONT_FAMILY = 'sans-serif'
  static GRAPH_FONT_WEIGHT = 300
  static targets = ['barGraph']
  static values = { chartData: Object }

  canvasContext () {
    return this.barGraphTarget.getContext('2d')
  }

  connect () {
    // eslint-disable-next-line no-new
    new Chart(this.canvasContext(), {
      type: 'bar',
      data: this.chartDataValue,
      options: this.options,
      plugins: [this.chartAreaBorder]
    })
  }

  get options () {
    const datasets = this.chartDataValue.datasets

    return {
      responsive: true,
      plugins: {
        chartAreaBorder: { borderColor: 'silver', borderWidth: 1 },
        legend: this.legend,
        subtitle: this.subtitle,
        tooltip: this.tooltip
      },
      scales: this.scales,
      onClick: function (evt, element) {
        if (element.length > 0) {
          const dataset = datasets[element[0].datasetIndex]
          const index = element[0].index
          if (dataset.stack === 'Classifications Generated') {
            window.location.href = dataset.urls[index]
          } else {
            window.location.href = dataset.urls[index]
          }
        }
      },
      onHover: function (evt, element) {
        evt.native.target.style.cursor = element[0] ? 'pointer' : 'default'
      }
    }
  }

  get chartAreaBorder () {
    return {
      id: 'chartAreaBorder',
      beforeDraw (chart, args, options) {
        const { ctx, chartArea: { left, top, width, height } } = chart
        ctx.save()
        ctx.strokeStyle = options.borderColor
        ctx.lineWidth = options.borderWidth
        ctx.strokeRect(left, top, width, height)
        ctx.restore()
      }
    }
  }

  get legend () {
    return {
      position: 'bottom',
      labels: {
        padding: 35,
        color: 'black',
        font: {
          size: 14,
          weight: RssSyncGraphController.GRAPH_FONT_WEIGHT,
          family: RssSyncGraphController.GRAPH_FONT_FAMILY
        },
        usePointStyle: true,
        pointStyle: 'rectRounded'
      }
    }
  }

  get subtitle () {
    return {
      display: true,
      text: 'Click on bar to navigate to list',
      position: 'bottom',
      color: 'darkgrey',
      font: {
        size: 12,
        weight: 100,
        family: RssSyncGraphController.GRAPH_FONT_FAMILY
      },
      padding: {
        top: -50
      }
    }
  }

  get tooltip () {
    return {
      backgroundColor: 'white',
      titleColor: 'black',
      titleFont: {
        size: 16,
        weight: RssSyncGraphController.GRAPH_FONT_WEIGHT,
        family: RssSyncGraphController.GRAPH_FONT_FAMILY
      },
      titleMarginBottom: 0,
      padding: 0,
      xAlign: 'center',
      yAlign: 'bottom',
      caretSize: 0,
      callbacks: {
        title: function (tooltipItems, data) {
          return tooltipItems[0].formattedValue
        },
        label: function (tooltipItem, data) {
          return ''
        }
      }
    }
  }

  get scales () {
    return {
      x: this.xscale,
      y: this.yscale
    }
  }

  get xscale () {
    return {
      grid: {
        offset: false,
        tickColor: 'black',
        tickWidth: 2,
        tickLength: 14
      },
      ticks: {
        color: 'black',
        font: {
          size: 11,
          weight: RssSyncGraphController.GRAPH_FONT_WEIGHT,
          family: RssSyncGraphController.GRAPH_FONT_FAMILY
        }
      }
    }
  }

  get yscale () {
    return {
      beginAtZero: true,
      ticks: {
        maxTicksLimit: 5,
        color: 'black',
        font: {
          size: 15,
          weight: RssSyncGraphController.GRAPH_FONT_WEIGHT,
          family: RssSyncGraphController.GRAPH_FONT_FAMILY
        }
      },
      grid: {
        tickColor: 'black',
        tickWidth: 2,
        tickLength: 10
      }
    }
  }
}
