import BaseController from './base_controller'

export default class extends BaseController {
  static values = { display: String }
  static targets = ['showable']

  // Show the element by updating its display value
  // to whatever the specified value is.
  show () {
    const el = this.showableTarget
    el.style.display = this.displayValue
  }

  // Hide the target element by changing its display
  // value to 'none'.
  hide () {
    const el = this.showableTarget
    el.style.display = 'none'
  }
}
