import { Controller } from '@hotwired/stimulus'
import { fire } from '../lib/form_events'

export default class extends Controller {
  static targets = ['startDate', 'endDate']

  static values = {
    start: String,
    end: String,
    allowEmptyBounds: Boolean
  }

  updateMinimumEndDate () {
    this.endDateTarget.min = this.startDateTarget.value
  }

  updateMaximumStartDate () {
    this.startDateTarget.max = this.endDateTarget.value
  }

  startDateKeyUp (event) {
    if (event.key === 'Enter') {
      this.updateStartValue()
    }
  }

  endDateKeyUp (event) {
    if (event.key === 'Enter') {
      this.updateEndValue()
    }
  }

  // compares and then updates this controller's startValue
  // if comparison sees startValue being changed, submit the form, else no need to submit
  updateStartValue () {
    const startValueChanged = this.startValue !== this.startDateTarget.value
    this.startValue = this.startDateTarget.value

    if (startValueChanged) {
      this.submitForm()
    }
  }

  // compares and then updates this controller's endValue
  // if comparison sees endValue being changed, submit the form, else no need to submit
  updateEndValue () {
    const endValueChanged = this.endValue !== this.endDateTarget.value
    this.endValue = this.endDateTarget.value

    if (endValueChanged) {
      this.submitForm()
    }
  }

  // form submission
  // only submits if both startDate and endDate are set, else do nothing
  submitForm () {
    if ((!this.allowEmptyBoundsValue && (this.startDateTarget.value && this.endDateTarget.value)) ||
        (this.allowEmptyBoundsValue && (this.startDateTarget.value || this.endDateTarget.value))) {
      const form = this.element.closest('form')
      if (form.dataset.remote) {
        fire(form, 'submit')
      } else {
        form.submit()
      }
    }
  }
}
