import BaseController from '../../base_controller'

// Enable/disable form submit button based on radio buttion slection
// and disable date inputs for the corresponding radio button if not selected
export default class extends BaseController {
  // place 'needValidation' target on the radio button that needs to be validated
  static targets = ['needValidation', 'dateInput', 'formSubmit']

  connect () {
    super.connect()
  }

  disconnect () {
    super.disconnect()
  }

  enableSubmitIfValidationNotNeeded (event) {
    if (!this.hasFormSubmitTarget) {
      return
    }

    if (event.currentTarget === this.needValidationTarget) {
      this.dateInputTargets.forEach(input => {
        input.disabled = false
      })
      this.formSubmitTarget.disabled = true
    } else {
      this.dateInputTargets.forEach(input => {
        input.disabled = true
      })
      this.formSubmitTarget.disabled = false
    }
  }
}
