import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    setTimeout(() => {
      this.submitButtonTarget.click()
    }, 2000)
  }
}
