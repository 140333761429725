import BaseController from './base_controller'

// Add this controller to an iframe that needs to maintain its scroll after toggling visibility.
// By default the browser will reset the iframes scroll position when it is set to display: none.
export default class extends BaseController {
  observer = null
  currentScrollPos = 0

  _onContentScroll = () => {
    const doc = this.element.contentWindow.document
    // dont update the scroll position if hiding
    if (this.element.offsetParent !== null) {
      // Chrome/Firefox on mac handle page scroll differently
      this.currentScrollPos = (doc.documentElement.scrollTop || doc.body.scrollTop)
    }
  }

  initialize () {
    this.observer = new IntersectionObserver((entries) => {
      if (entries.length > 0 && entries[0].intersectionRatio > 0) {
        this.element.contentWindow.document.documentElement.scrollTop = this.currentScrollPos
        this.element.contentWindow.document.body.scrollTop = this.currentScrollPos
      }
    })
  }

  connect () {
    this.element.contentWindow?.addEventListener('scroll', this._onContentScroll)
    this.observer.observe(this.element)
  }

  disconnect () {
    this.element.contentWindow?.removeEventListener('scroll', this._onContentScroll)
    this.observer.unobserve(this.element)
  }
}
