import { fire } from '../../../lib/form_events'
import BaseController from '../../base_controller'

export default class extends BaseController {
  static targets = [
    'manageModal', // manage button
    'checkbox', // checkbox on each row
    'selectAll', // 'select all' checkbox
    'count', // count within the manage modal

    'bulkAssignModal', // modal that contains bulk assign form
    'bulkAssignForm', // submission form to update bulk selected companies
    'bulkAssignFormCompanyIdsInput' // hidden input on submission form for company ids
  ]

  connect () {
    super.connect()
  }

  clickCheckbox () {
    // update count
    let checkedCount = 0
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        checkedCount++
      }
    })
    this.countTarget.innerHTML = checkedCount

    // if all checkboxes are 'checked' then mark selectAll
    this.selectAllTarget.checked = checkedCount === this.checkboxTargets.length
  }

  clickSelectAll () {
    let checkedCount = 0
    this.checkboxTargets.forEach(checkbox => {
      if (this.selectAllTarget.checked) {
        checkedCount++
      }

      // make each checkbox match the selectAll's checked state
      checkbox.checked = this.selectAllTarget.checked
    })

    this.countTarget.innerHTML = checkedCount
  }

  submitBulkAssignForm () {
    // set selected company ids as hidden input value before submitting form
    const companyIds = []
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        companyIds.push(checkbox.dataset.companyId)
      }
    })
    this.bulkAssignFormCompanyIdsInputTarget.value = companyIds

    fire(this.bulkAssignFormTarget, 'submit')

    this.bulkAssignModalTarget.hidden = true
  }
}
