import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'editForm'
  ]

  submitEditForm () {
    this.editFormTarget.submit()
  }
}
