import BaseController from '../base_controller'

// Stimulus controller to hide/show target content based on universe type selection
// For the selector and the contents to work correctly, content element must have "data-universe-type-content-name" attribute
// set to one of the selector option values
export default class extends BaseController {
  static targets = ['selector', 'content', 'defaultContent']

  showSelectedContent () {
    const selectedValue = this.selectorTarget.value
    const defaultContent = this.defaultContentTarget

    this.contentTargets.forEach(content => {
      const contentName = content.dataset.universeTypeContentName

      if (selectedValue === '' && content === defaultContent) {
        defaultContent.hidden = false
      } else if (selectedValue === contentName) {
        content.hidden = false
      } else {
        content.hidden = true
      }
    })
  }
}
