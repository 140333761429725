import { Controller } from '@hotwired/stimulus'

// This controller is used to reset a value on a field in a form after submission. It waits a millisecond to prevent
//  changing the value in the form from resetting before sending the request
export default class extends Controller {
  static targets = ['field']

  static values = { value: Number }

  resetValue () {
    setTimeout(() => {
      this.fieldTarget.value = this.valueValue
    }, 1)
  }
}
