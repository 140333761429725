import BaseController from './base_controller'
import tippy from 'tippy.js'

export default class TippyTooltipController extends BaseController {
  static targets = ['trigger', 'content']
  static values = {
    placement: { type: String, default: 'bottom' }, // options: https://atomiks.github.io/tippyjs/v6/all-props/#placement
    arrow: { type: Boolean, default: false },
    duration: { type: Array, default: [300, 250] }, // transition duration [show duration, hide duration] https://atomiks.github.io/tippyjs/v6/all-props/#duration
    interactive: { type: Boolean, default: false }, // interactive determines if the tippy has interactive content inside of it, so that it can be hovered over and clicked inside without hiding. https://atomiks.github.io/tippyjs/v6/all-props/#interactive
    maxWidth: { type: Number, default: 485 }, // max width of tooltip; numbers in px; can use 'none'
    offset: { type: Array, default: [0, 3] }, // offset [skidding, distance] is to position the tooltip relative the trigger element https://popper.js.org/docs/v2/modifiers/offset
    theme: { type: String, default: 'white_on_black' },
    trigger: { type: String, default: 'mouseenter focus' }, // Determines which event toggles the tooltip
    delay: { type: Array, default: [0, 0] }, // How long we wait before displaying/hiding the tooltip
    content: { type: String, default: '' }, // Set the tooltip content as an attribute on the button, rather than a target
    autoHideAfter: { type: Number, default: 0 }, // Add a timeout on the tooltip
    resetTooltipAfterClose: { type: Boolean, default: false }, // Destroy and rebuild the tooltip on each load. Useful if the tooltip content can change
    appendToName: { type: String, default: '' } // Set this value to use a selector to find an element to append tippys to
  }

  initialize () {
    this.initPopup()
    if (this.hasContentTarget) {
      this.contentTarget.style.display = 'none'
    }
  }

  initPopup () {
    this.trigger = null
    if (this.hasTriggerTarget) {
      this.trigger = this.triggerTarget
    } else {
      this.trigger = this.element
    }

    let appendTo = document.body
    if (this.appendToNameValue !== '') {
      appendTo = document.querySelector(`[data-append-to="${this.appendToNameValue}"]`)
    }

    tippy(this.trigger, {
      placement: this.placementValue,
      content: this.tooltipContent(),
      allowHTML: true,
      appendTo: () => appendTo,
      arrow: this.arrowValue,
      duration: this.durationValue,
      interactive: this.interactiveValue,
      maxWidth: this.maxWidthValue,
      offset: this.offsetValue,
      theme: this.themeValue,
      trigger: this.triggerValue,
      delay: this.delayValue,
      autoHideAfter: this.autoHideAfterValue,
      resetTooltipAfterClose: this.resetTooltipAfterCloseValue,
      onShow (instance) {
        if (instance.props.autoHideAfter > 0) {
          setTimeout(() => {
            instance.hide()
          }, instance.props.autoHideAfter)
        }
      },
      onHide (instance) {
        if (instance.props.resetTooltipAfterClose) {
          if (!instance.props.reset) {
            instance.props.reset = true
            instance.destroy()
            instance.reference.controllers['tippy-tooltip'].initPopup()
          }
        }
      }
    })
  }

  tooltipContent () {
    if (this.hasContentTarget) {
      return this.contentTarget.innerHTML
    } else if (this.contentValue !== '') {
      return this.contentValue
    }
  }

  setTooltipContent (newContent) {
    this.trigger._tippy.setContent(newContent)
  }
}
