import BaseController from './base_controller'

export default class NestedDropdownController extends BaseController {
  initialize () {
    this.openTippys = []
  }

  mouseEnterOption (e) {
    const submenuLevel = parseInt(e.target.dataset.submenuLevel)
    const tippyInstance = e.target._tippy

    let openTippyObj = null
    while (this.openTippys.length > 0 && this.openTippys[this.openTippys.length - 1].level >= submenuLevel) {
      openTippyObj = this.openTippys.pop()
      openTippyObj.instance.hide()
      openTippyObj.highlightElement.classList.remove('highlight-for-submenu')
    }

    tippyInstance.show()

    const highlightElement = e.target.querySelector('.reporting-metric-row')
    this.openTippys.push({ instance: tippyInstance, level: submenuLevel, highlightElement })

    highlightElement.classList.add('highlight-for-submenu')
  }

  mouseLeaveOption (e) {
    const tippyInstance = e.target._tippy
    const hoveredElements = this.element.querySelectorAll(':hover')

    if (hoveredElements.length === 0) {
      tippyInstance.hide()
      return
    }

    const hoveredElement = hoveredElements[hoveredElements.length - 1]

    // Only hide the element if we aren't currently hovering over the popper
    if (hoveredElement.closest('[data-tippy-root]') !== tippyInstance.popper) {
      tippyInstance.hide()

      e.target.querySelector('.reporting-metric-row').classList.remove('highlight-for-submenu')
    }
  }

  clickOption () {
    while (this.openTippys.length > 0) {
      this.openTippys.pop().instance.hide()
    }
  }
}
