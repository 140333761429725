import BaseController from '../base_controller'

export default class extends BaseController {
  connect () {
    super.connect()
    this.expandRbrs = false
  }

  toggleRbrsExpanded () {
    if (this.expandRbrs) {
      this.dispatch('expandRbrs')
      this.setButtonToCollapse()
    } else {
      this.dispatch('collapseRbrs')
      this.setButtonToExpand()
    }
  }

  checkAllExpanded () {
    let allCollapsed = true
    document.querySelectorAll('.rbr-row.level-2').forEach((rbrRow) => {
      const expanded = rbrRow.querySelector('.bucket-expand-collapse').dataset.expandState === 'true'
      if (expanded) { allCollapsed = false }
    })

    if (allCollapsed === this.expandRbrs) return

    if (this.expandRbrs) {
      this.setButtonToCollapse()
    } else {
      this.setButtonToExpand()
    }
  }

  setButtonToExpand () {
    this.expandRbrs = true
    this.element.querySelector('.material-icons').innerHTML = 'unfold_more'
    const container = this.element.closest('[data-tippy-tooltip-content-value]')
    container.controllers['tippy-tooltip'].setTooltipContent('Expand All')
  }

  setButtonToCollapse () {
    this.expandRbrs = false
    this.element.querySelector('.material-icons').innerHTML = 'unfold_less'
    const container = this.element.closest('[data-tippy-tooltip-content-value]')
    container.controllers['tippy-tooltip'].setTooltipContent('Collapse All')
  }
}
