import { fire } from '../lib/form_events'
import BaseController from './base_controller'

export default class AssociatedPrivateClassificationsController extends BaseController {
  static targets = [
    'checkBox',
    'field',
    'form'
  ]

  associatedPrivateClassificationsUpdate () {
    if (this.checkBoxTarget.checked) {
      this.fieldTarget.value = 'yes'
    } else {
      this.fieldTarget.value = 'no'
    }

    fire(this.formTarget, 'submit')
  }
}
