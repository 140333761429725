import { Controller } from '@hotwired/stimulus'

// The base controller modifies the base stimulus controller to attach the
// instance of the controller to the HTML element when it connects. This allows
// other javascript or stimulus controllers to call methods implemented in this
// controller programmatically.
export default class BaseController extends Controller {
  connect () {
    this.element.controllers ||= {}
    this.element.controllers[this.identifier] = this
  }

  disconnect () {
    this.element.controllers ||= {}
    delete this.element.controllers[this.identifier]
  }
}
