import moment from 'moment'
import BaseController from '../base_controller'

export default class CreateClassificationFormController extends BaseController {
  static targets = ['classificationName', 'fiscalYearEnd', 'fiscalYearEndErrMsg', 'submitButton']

  formInputChanged () {
    const noClassificationName = !this.classificationNameTarget.value
    const noFiscalYearEnd = !this.fiscalYearEndTarget.value

    if (noFiscalYearEnd) {
      this.fiscalYearEndErrMsgTarget.hidden = true
      this.submitButtonTarget.disabled = noClassificationName
    } else {
      // validate fiscal year end within range
      const date = moment(this.fiscalYearEndTarget.value)
      const earliestAllowedDate = moment('1950-01-01')
      const latestAllowedDate = moment()
      const dateNotValid = date.isBefore(earliestAllowedDate) || date.isSameOrAfter(latestAllowedDate)

      this.fiscalYearEndErrMsgTarget.hidden = !dateNotValid
      this.submitButtonTarget.disabled = noClassificationName || dateNotValid
    }
  }
}
