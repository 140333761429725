import { clamp, debounce } from 'lodash'
import { useClickOutside } from 'stimulus-use'
import BaseController from './base_controller'

export default class FetchAutocompleteController extends BaseController {
  downKey = 'ArrowDown'
  upKey = 'ArrowUp'
  navigationKeys = [this.downKey, this.upKey]

  static targets = ['input', 'hiddenInput', 'results', 'result']

  static classes = ['selected']

  static values = {
    url: String,
    urlParams: Object
  }

  connect () {
    super.connect()
    useClickOutside(this)

    // debounce isn't necessary if the request responds quickly, set 100ms here as a baseline
    // if requests start taking longer, consider:
    // - setting the debounce time with a Stimulus valueTarget
    // - adding a requestIndex (or requestTerm) parameter, and checking against it before setting the results
    this.autocomplete = debounce(this.autocomplete, 100)

    // Index of the selected option in the results list.
    this.index = -1
  }

  autocomplete () {
    // if setting autocomplete result into hidden input, clear it before each new autocomplete fetch request
    if (this.hasHiddenInputTarget) {
      this.updateHiddenInputTargetValue('')
    }

    this.fetchResults()
  }

  fetchResults () {
    fetch(this.buildURL())
      .then(response => response.text())
      .then(data => this.displayResults(data))
  }

  displayResults (results) {
    this.resultsTarget.innerHTML = results

    this.showResults()
  }

  selectResult (event) {
    const result = event.params.result

    if (this.hasHiddenInputTarget) {
      this.updateHiddenInputTargetValue(result.value)

      this.inputTarget.value = result.text
    } else {
      this.updateInputTargetValue(result.value)
    }
  }

  clickSelectedResult () {
    const el = this.resultTargets[this.index]
    if (el) {
      const mousedown = new Event('mousedown')
      el.dispatchEvent(mousedown)
    }
  }

  selectResultFromEnter (event) {
    event.preventDefault()
    this.clickSelectedResult()

    const blur = new Event('blur')
    event.target.dispatchEvent(blur)
  }

  // Method to navigate through our list results using
  // the arrow keys and enter key.
  // @param event [Event] the event we want to check
  navigateResults (event) {
    event.preventDefault()
    const prevIndex = this.index
    let newIndex = -1

    if (event.type === 'mouseover') {
      newIndex = this.resultTargets.indexOf(event.target)
    } else if (event.key === this.upKey) {
      newIndex = this.index - 1
    } else if (event.key === this.downKey) {
      newIndex = this.index + 1
    } else {
      return
    }

    // If no valid index, take no action.
    if (newIndex === -1) {
      return
    }

    // Remove the old selected state.
    if (this.resultTargets[prevIndex]) {
      this.resultTargets[prevIndex].classList.remove(this.selectedClass)
    }

    // Select the new result.
    this.index = clamp(newIndex, -1, this.resultTargets.length - 1)
    if (this.index >= 0) {
      this.resultTargets[this.index].focus()
      this.resultTargets[this.index].scrollIntoViewIfNeeded()
      this.resultTargets[this.index].classList.add(this.selectedClass)
    }
  }

  buildURL () {
    const url = new URL(this.urlValue, window.location.href)

    for (const [name, value] of Object.entries(this.urlParamsValue)) {
      url.searchParams.append(name, value)
    }

    url.searchParams.append('search_term', this.inputTarget.value.trim())

    return url.toString()
  }

  updateHiddenInputTargetValue (value) {
    this.hiddenInputTarget.value = value
    this.hiddenInputTarget.dispatchEvent(new Event('change'))
  }

  updateInputTargetValue (value) {
    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  showResults () {
    this.resultsTarget.hidden = false
  }

  hideResults () {
    this.resultsTarget.hidden = true
  }
}
