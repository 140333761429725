import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'button']

  showStatusBar () {
    this.buttonTarget.disabled = true

    const exportStatusBar = document.querySelector("[data-ujs-id='export_status_bar']")
    exportStatusBar.style.display = 'block'
    exportStatusBar.innerHTML = 'Cleaning Watchlist...'
    this.formTarget.submit()
  }
}
