import { Controller } from '@hotwired/stimulus'
import { fire } from '../../lib/form_events'

export default class BulkProductLineModalController extends Controller {
  static targets = ['checkbox', 'clearAllButton', 'clearRevenueButton', 'form', 'selectAllButton',
    'plsForDeletion', 'plsForClearAll', 'plsForClearRevenue']

  selectOrDeselectAll () {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = this.selectAllButtonTarget.checked
    })
    this.updateButtons()
  }

  setSelectAllState () {
    let allChecked = true
    let someChecked = false
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        someChecked = true
      } else {
        allChecked = false
      }
    })
    if (allChecked) {
      this.selectAllButtonTarget.indeterminate = false
      this.selectAllButtonTarget.checked = true
    } else if (someChecked) {
      this.selectAllButtonTarget.indeterminate = true
    } else {
      this.resetSelectAll()
    }
  }

  setSelectAllStateAndUpdateButtons () {
    this.setSelectAllState()
    this.updateButtons()
  }

  updateButtons () {
    let restrictionLevel
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        const productLineRow = checkbox.closest('.pl-row')
        if (productLineRow.dataset.markedFor === 'deletion') {
          restrictionLevel = 'deletion'
        } else if (productLineRow.dataset.markedFor === 'clear_all' && restrictionLevel !== 'deletion') {
          restrictionLevel = 'clear_all'
        }
      }
    })

    if (restrictionLevel === 'deletion') {
      this.clearAllButtonTarget.dataset.disabled = true
      this.clearRevenueButtonTarget.dataset.disabled = true
    } else if (restrictionLevel === 'clear_all') {
      this.clearAllButtonTarget.dataset.disabled = false
      this.clearRevenueButtonTarget.dataset.disabled = true
    } else {
      this.resetButtons()
    }
  }

  deletePl () {
    this.setCheckedMarking('deletion')
  }

  clearPl () {
    if (this.clearAllButtonTarget.dataset.disabled === 'true') {
      return
    }

    this.setCheckedMarking('clear_all')
  }

  clearRevenue () {
    if (this.clearRevenueButtonTarget.dataset.disabled === 'true') {
      return
    }

    this.setCheckedMarking('clear_revenue')
  }

  resetPl () {
    this.setCheckedMarking('')
  }

  submit () {
    const plsForDeletion = []
    const plsForClearAll = []
    const plsForClearRevenue = []
    this.checkboxTargets.forEach(checkbox => {
      const markedFor = checkbox.closest('.pl-row').dataset.markedFor
      const productLineId = checkbox.dataset.productLineId
      if (markedFor === 'deletion') {
        plsForDeletion.push(productLineId)
      } else if (markedFor === 'clear_all') {
        plsForClearAll.push(productLineId)
      } else if (markedFor === 'clear_revenue') {
        plsForClearRevenue.push(productLineId)
      }
    })

    this.plsForDeletionTarget.value = plsForDeletion.join(',')
    this.plsForClearAllTarget.value = plsForClearAll.join(',')
    this.plsForClearRevenueTarget.value = plsForClearRevenue.join(',')
    fire(this.formTarget, 'submit')
  }

  setCheckedMarking (markedFor) {
    this.checkboxTargets.forEach(checkbox => {
      const productLineRow = checkbox.closest('.pl-row')
      if (checkbox.checked) {
        productLineRow.dataset.markedFor = markedFor
        checkbox.checked = false
      }
    })
    this.resetButtons()
    this.resetSelectAll()
  }

  resetButtons () {
    this.clearAllButtonTarget.dataset.disabled = false
    this.clearRevenueButtonTarget.dataset.disabled = false
  }

  resetSelectAll () {
    this.selectAllButtonTarget.indeterminate = false
    this.selectAllButtonTarget.checked = false
  }
}
