import BaseController from './base_controller'

export default class extends BaseController {
  // State can be either: 'expanded', 'collapsed' (default)
  static values = {
    state: { type: String, default: 'collapsed' }
  }

  static outlets = ['expand-collapse', 'swap-content']

  toggle () {
    if (this.stateValue === 'collapsed') {
      this.expandCollapseOutlets.forEach(result => result.expand())
      this.stateValue = 'expanded'
    } else {
      this.expandCollapseOutlets.forEach(result => result.collapse())
      this.stateValue = 'collapsed'
    }
  }

  determineState () {
    const curState = this.stateValue

    const expanded = this.expandCollapseOutlets.filter(
      el => el.stateValue === 'expanded'
    )

    let newState
    if (expanded.length > 0) {
      newState = 'expanded'
    } else {
      newState = 'collapsed'
    }

    if (curState !== newState) {
      this.stateValue = newState
      this.swapContentOutlet.swap()
    }
  }
}
