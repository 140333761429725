import BaseController from '../base_controller'

export default class DashboardProjectGroupsController extends BaseController {
  static targets = ['projectGroup', 'projectGroupRow']

  showProjectGroup () {
    const selectedProjectGroupId = event.params.projectGroupId

    // Show selected project group
    this.projectGroupTargets.forEach(projectGroup => {
      const projectGroupId = Number(projectGroup.dataset.projectGroupId)

      if (projectGroupId === selectedProjectGroupId) {
        projectGroup.hidden = false
      } else {
        projectGroup.hidden = true
      }
    })

    // Highlight selected project group row
    this.projectGroupRowTargets.forEach(projectGroupRow => {
      const projectGroupId = Number(projectGroupRow.dataset.projectGroupId)

      if (projectGroupId === selectedProjectGroupId) {
        projectGroupRow.querySelector('.row').classList.add('selected')
      } else {
        projectGroupRow.querySelector('.row').classList.remove('selected')
      }
    })
  }
}
