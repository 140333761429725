import BaseController from './base_controller'

export default class GlobalIdCalloutController extends BaseController {
  static targets = ['callout', 'calloutContent', 'tidError']

  static values = {
    type: String
  }

  connect () {
    super.connect()
  }

  showCallout (event) {
    const data = event.params.data
    const name = data.name
    const globalId = data.value
    const archiveOnly = data.archive_only

    if (!globalId) {
      return
    }

    this.calloutContentTarget.innerHTML = this.calloutText(name, globalId, archiveOnly)
    this.calloutTarget.hidden = false
  }

  hideCallout () {
    this.calloutTarget.hidden = true
  }

  hideError () {
    if (this.hasTidErrorTarget) {
      this.tidErrorTarget.hidden = true
    }
  }

  calloutText (name, globalId, archiveOnly) {
    const item = this.typeValue === 'TID' ? 'taxonomy' : 'bucket'

    let text =
      `${globalId}, which was originally ${name}'s ${this.typeValue}, ` +
      `will replace the current ${this.typeValue} for this ${item}.`

    if (!archiveOnly) {
      text += ` ${name} will be assigned a new ${this.typeValue}.`
    }

    return text
  }
}
