import BaseController from '../base_controller'
import { useClickOutside } from 'stimulus-use'
import { debounce } from 'lodash'
const enterKey = 'Enter'

// Governs retrieving and placing companies on Corporate Action modal
export default class extends BaseController {
  static targets = ['searchField', 'results', 'companyHiddenInput']
  static values = { maxResults: Number }

  initialize () {
    super.initialize()
    this.submitCompanyQuery = debounce(this.submitCompanyQuery, 250)
  }

  connect () {
    super.connect()
    useClickOutside(this)
  }

  // Submit query to company autocomplete
  submitCompanyQuery () {
    fetch('/companies/autocomplete?' + this.searchParams())
      .then(response => response.json())
      .then(json => this.displayResults(json))
  }

  // Renders results under search input
  // @param results [Object] results from companies autocomplete query
  displayResults (results) {
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.classList.remove('hidden')
    this.resultsTarget.appendChild(this.renderResults(results.slice(0, this.maxResultsValue)))
  }

  // @param results [Object] results from companies autocomplete query
  // @return [HTML] Results list to render under search input
  renderResults (results) {
    const list = document.createElement('ul')

    results.forEach(result => {
      const li = document.createElement('li')
      li.setAttribute('data-company-id', result.value)
      li.setAttribute('data-company-name', result.label)
      li.setAttribute('tabindex', 0)
      li.setAttribute('data-action', this.resultActions())
      li.innerHTML = result.label
      list.appendChild(li)
    })

    return list
  }

  // @return [String] Actions for result item
  resultActions () {
    return 'click->corporate-actions--company-autocomplete#applyResult ' +
        'keyup->corporate-actions--company-autocomplete#applyResultOnEnter'
  }

  clickOutside () {
    this.searchFieldTarget.value = this.searchFieldTarget.dataset.currentCompanyName
    this.resultsTarget.classList.add('hidden')
  }

  // Run's applyResult if user hit enter while focused on active element
  // @param event [PointerEvent]
  applyResultOnEnter (event) {
    if (event.key === enterKey && event.currentTarget === document.activeElement) {
      this.applyResult(event)
    }
  }

  // Applies company name and ID of selected company to search bar and hidden field when company is selected
  // @param event [PointerEvent]
  applyResult (event) {
    const companyId = event.currentTarget.dataset.companyId
    const companyName = event.currentTarget.dataset.companyName
    this.searchFieldTarget.dataset.currentCompanyName = companyName
    this.searchFieldTarget.value = companyName
    this.companyHiddenInputTarget.value = companyId
    this.resultsTarget.classList.add('hidden')
    this.validateInputs()
  }

  // triggers validation on corporate actions form validation controller
  validateInputs () {
    const corporateActionsForm = document.querySelector('#corporate_actions_form')
    const validationCntrl = this.application.getControllerForElementAndIdentifier(corporateActionsForm, 'corporate-actions--form-validation')
    validationCntrl.validateInputs()
  }

  // @return [Array<String>] An array of company IDs already on the form to exclude from query
  excludedCompanyIdParams () {
    const companyFields = document.querySelectorAll('.company-rows-container .company-corporate-action-company-ids')
    return [].map.call(companyFields, el => el.value).filter(companyId => companyId !== '')
  }

  // @return [URLSearchParams] search parameters for query
  searchParams () {
    const params = [['search_term', this.searchFieldTarget.value]]
      .concat(this.excludedCompanyIdParams().map(id => ['company_ids_excluded[]', id]))
    return new URLSearchParams(params)
  }
}
