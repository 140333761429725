import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'checkbox']

  check () {
    let allUnchecked = true
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        allUnchecked = false
      }
    })

    this.submitButtonTarget.disabled = allUnchecked
  }
}
