import BaseController from './base_controller'

export default class NoteCitationLocationFieldsController extends BaseController {
  static targets = ['locationField']

  locationFieldTargetConnected () {
    this.updateLocationFieldDataValues()
  }

  locationFieldTargetDisconnected () {
    this.updateLocationFieldDataValues()
  }

  updateLocationFieldDataValues () {
    const locationGIDs =
      this.locationFieldTargets.filter(field => {
        return field.closest('[style*="display: none;"]') == null
      }).map(field => field.value)

    this.locationFieldTargets.forEach(field => {
      field.dataset.existingLocations = JSON.stringify(locationGIDs)
    })
  }
}
