import BaseController from './base_controller'

export default class CopyValueController extends BaseController {
  static targets = ['copyToField']
  static values = {
    copyFromFieldId: String
  }

  copy () {
    this.copyToFieldTarget.value = document.getElementById(this.copyFromFieldIdValue).value
  }
}
