import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = [
    'emptyField',
    'field',
    'container',
    'form',
    'warning',
    'submit'
  ]

  connect () {
    this.focusFirstEmptyField()
  }

  // Checks if the last keyword field has a value in it.
  // If it does, insert a new blank one beneath it.
  insert () {
    const [lastField] = this.fieldTargets.slice(-1)
    const keyword = lastField.querySelector('input').value
    if (keyword !== '') {
      this.appendNewField()
      this.focusLastField()
    }
  }

  // Checks if the last keyword field has a value in it.
  // If it does, insert a new blank one beneath it.
  checkDuplicates () {
    // Remove old duplicate messages.
    for (const field of this.fieldTargets) {
      const toRemove = field.querySelectorAll('.duplicate-keyword-warning')
      toRemove.forEach(el => el.remove())
    }

    // Add new duplicate messages.
    const inputs = this.fieldTargets.map(
      field => field.querySelector('input')
    )
    const duplicateInputs = this.findDuplicates(inputs)

    for (const [, inputs] of duplicateInputs) {
      // Don't highlight the first duplicate field,
      // only the subsequent "duplicates"
      inputs.shift()

      for (const input of inputs) {
        const warning = this.warningTarget
          .querySelector('.duplicate-keyword-warning')
          .cloneNode(true)

        input.parentElement.after(warning)
      }
    }
  }

  // Ensures that a taxonomy has been selected AND at
  // least one keyword field has been filled out, before
  // allowing the form to submitted.
  checkDisabled () {
    const inputs = this.fieldTargets.map(
      field => field.querySelector('input')
    )
    const nonEmptyInput = Array.from(inputs).find(
      el => el.value !== ''
    )

    const hasTaxonomyInput =
      this.formTarget.querySelector('#rbr_taxonomy_name').value !== ''

    if (nonEmptyInput && hasTaxonomyInput) {
      this.submitTarget.classList.remove('disabled')
    } else {
      this.submitTarget.classList.add('disabled')
    }
  }

  // Removes a text field. If required, inserts a new
  // blank field so that there is always one present.
  remove () {
    const fieldToRemove = this.fieldTargets.find(
      el => el.contains(event.target)
    )
    fieldToRemove.remove()

    const noneRemaining = this.fieldTargets.length === 0
    const allFilled = this.fieldTargets.every(
      el => el.querySelector('input').value !== ''
    )

    if (noneRemaining || allFilled) {
      this.appendNewField()
    }

    event.stopImmediatePropagation()
    this.focusLastField()
    this.checkDisabled()
  }

  // Adds a new blank field to the bottom of the form.
  appendNewField () {
    const newField = this.emptyFieldTarget.cloneNode(true)
    newField.setAttribute(
      'data-admin-panel--taxonomy-keyword-forms-target',
      'field'
    )
    this.containerTarget.appendChild(newField)
    this.focusLastField()
  }

  // Focus on the first empty input field in the form.
  focusFirstEmptyField () {
    const inputs = this.formTarget.querySelectorAll('input')
    const fieldToFocus = Array.from(inputs).find(
      el => el.value === ''
    )

    fieldToFocus.focus()
  }

  // Focuses on the input in the last field in the form.
  focusLastField () {
    const [lastField] = this.fieldTargets.slice(-1)
    lastField.querySelector('input').focus()
  }

  // Given a list of elements (eg., <input> tags), find and
  // return all the duplicate elements
  findDuplicates (els) {
    const values = Array.from(els)
      .map(el => el.value)
      .filter(value => value !== '')

    const duplicateValues = values.filter((item, index) =>
      values.indexOf(item) !== index
    )

    const duplicateEls = new Map()

    for (const el of els) {
      const value = el.value

      if (duplicateValues.indexOf(value) === -1) {
        continue
      }

      // If the map already contains one of the duplicate
      // elements, append the other to the array. If not,
      // init the key/value pair with a new array.
      if (duplicateEls.has(value)) {
        const dupInputs = duplicateEls.get(value)
        dupInputs.push(el)
        duplicateEls.set(value, dupInputs)
      } else {
        duplicateEls.set(value, [el])
      }
    }

    return duplicateEls
  }
}
