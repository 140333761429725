import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'fiscalYearEnd',
    'fiscalYearDateAlert',
    'fiscalYearConflictAlert',
    'noDocDateAlert',
    'fiscalYearsTooltip'
  ]

  static values = {
    existingFiscalYearEnd: Array,
    noDocDateRanges: Array
  }

  connect () {
    this.fiscalYearEndChanged()
  }

  fiscalYearEndChanged () {
    const fiscalYearUnique = classificationFiscalYearUnique(this.existingFiscalYearEndValue,
      this.fiscalYearEndTarget.value)

    // Checking if this fiscal year end value conflicts with
    // existing classification.fiscal_year_end
    if (fiscalYearUnique) {
      this.fiscalYearConflictAlertTarget.classList.add('hidden')
      this.fiscalYearEndTarget.setCustomValidity('')
      this.fiscalYearEndTarget.valid = true
    } else {
      this.fiscalYearConflictAlertTarget.classList.remove('hidden')
      this.fiscalYearEndTarget.setCustomValidity('A classification already exists with this Fiscal Year End')
      this.fiscalYearEndTarget.valid = false
    }

    // Checking the min and max date values allowed in the date picker
    if (this.fiscalYearEndTarget.validity.rangeUnderflow || this.fiscalYearEndTarget.validity.rangeOverflow) {
      this.fiscalYearDateAlertTarget.classList.remove('hidden')
    } else {
      this.fiscalYearDateAlertTarget.classList.add('hidden')
    }

    // Checking if fiscal year end date is within the no documnet date ranges
    if (fiscalYearEndOverlapNoDocDateRanges(this.noDocDateRangesValue, this.fiscalYearEndTarget.value)) {
      this.noDocDateAlertTarget.classList.remove('hidden')
    } else {
      this.noDocDateAlertTarget.classList.add('hidden')
    }
  }

  fiscalYearsIconMouseOver () {
    this.fiscalYearsTooltipTarget.hidden = false
  }

  fiscalYearsIconMouseOut () {
    this.fiscalYearsTooltipTarget.hidden = true
  }
}

function classificationFiscalYearUnique (existingFiscalYears, selectedFiscalYear) {
  let uniqueFiscalYear = true
  existingFiscalYears.forEach(function (item) {
    if (Date.parse(item.toString()) === Date.parse(selectedFiscalYear.toString())) {
      uniqueFiscalYear = false
    }
  })
  return uniqueFiscalYear
}

// Checking if fiscal year end date is within the no documnet date ranges
// @param noDocDateRanges [Array<Array>] array that contains [start_date, end_date] date ranges
// @param fiscalYearEnd [String]
function fiscalYearEndOverlapNoDocDateRanges (noDocDateRanges, selectedFiscalYear) {
  const fisaclYearEndDate = Date.parse(selectedFiscalYear.toString())

  return noDocDateRanges.some((dateRange) => {
    return (Date.parse(dateRange[0].toString()) <= fisaclYearEndDate && fisaclYearEndDate <= Date.parse(dateRange[1].toString()))
  })
}
