import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectAllCheckbox', 'checkbox']

  toggleCheckboxes (event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })
  }

  updateSelectAllCheckbox () {
    let allChecked = true
    this.checkboxTargets.forEach(checkbox => {
      if (!checkbox.checked) {
        allChecked = false
      }
    })

    this.selectAllCheckboxTarget.checked = allChecked
  }
}
