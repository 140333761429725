import BaseController from './base_controller'

export default class extends BaseController {
  static values = { content: String }
  static targets = ['swappable', 'swapWith']

  swap () {
    const el = this.swappableTarget

    // Cache the current content
    const cur = el.innerHTML

    // We can substitute data from either a DOM element
    // OR a string stored in the swappable value.
    if (this.hasSwapWithTarget) {
      el.innerHTML = this.swapWithTarget.innerHTML
      this.swapWithTarget.innerHTML = cur
    } else {
      el.innerHTML = this.contentValue
      this.contentValue = cur
    }
  }
}
