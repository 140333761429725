import moment from 'moment'
import BaseController from '../../base_controller'

export default class ProjectFormController extends BaseController {
  static targets = [
    'createBtn',

    // validation - project source
    'projectSourceInput',

    // validation - end dates
    'endDateInput',

    // validation - user selects
    'userCheckbox',

    // validation - status
    'statusRadioBtn',

    // add/remove date ranges
    'dateRangesContainer',
    'dateRange'
  ]

  static values = {
    nextDateRangeId: Number,
    existingDateRanges: Array,
    projectSourceType: String
  }

  connect () {
    super.connect()

    this.nextDateRangeId = this.nextDateRangeIdValue

    this.validateForm()
  }

  /* Fetch Existing Date Ranges (for validation) on Autocomplete Selection */
  updateExistingDateRanges () {
    fetch(this.buildExistingDateRangesFetchUrl())
      .then(response => response.json())
      .then(data => {
        this.existingDateRangesValue = data
        this.validateExistingEndDates()
      })
  }

  buildExistingDateRangesFetchUrl () {
    const url = new URL('/projects/existing_date_ranges_on_source', window.location.origin)

    url.searchParams.append('source_type', this.projectSourceTypeValue)
    url.searchParams.append('source_id', this.projectSourceInputTarget.value)
    return url.toString()
  }

  /* Add/Remove Date Range Functions */
  addDateRange () {
    const newDateRange = this.dateRangeHtml()
    this.dateRangesContainerTarget.insertAdjacentHTML('beforeend', newDateRange)

    this.nextDateRangeId++ // ensure that every newly created date range always has a unique id

    this.validateForm()
  }

  deleteDateRange (event) {
    // need to stop event propagation
    // otherwise this function will remove the selected date range and the click
    // will then be propagated to outside the New Project modal and cause the modal to close
    event.stopPropagation()

    const dateRangeToDelete = event.target.closest(
      "[data-admin-panel--dashboard-projects--project-form-target='dateRange']"
    )

    if (this.dateRangeTargets.length === 1) {
      this.clearLastDateRange()
    } else {
      let deleteIndex
      this.dateRangeTargets.forEach((dateRange, index) => {
        if (dateRange === dateRangeToDelete) {
          deleteIndex = index
        }
      })
      this.dateRangeTargets[deleteIndex].remove()
    }

    this.validateForm()
    this.validateExistingEndDates()
  }

  clearLastDateRange () {
    const lastDateRange = this.dateRangeTargets[0]

    lastDateRange.querySelectorAll('input').forEach(input => {
      input.value = ''
    })

    lastDateRange.querySelector('.error-date-out-of-range').hidden = true
  }

  dateRangeHtml () {
    const html = `
      <div class="flex mb-4 date-range"
           data-controller="admin-panel--dashboard-projects--fixed-date-range"
           data-admin-panel--dashboard-projects--project-form-target="dateRange">
        <div class="flex-column mr-5">
          <label for="start_date_${this.nextDateRangeId}">Start Date</label>
          <input type="date" tabindex="-1"
                 class="start-date-input"
                 id="start_date_${this.nextDateRangeId}"
                 data-admin-panel--dashboard-projects--fixed-date-range-target="startDate" />
        </div>
        <div class="flex-column mr-2">
          <label for="end_date_${this.nextDateRangeId}">End Date</label>
          <div class="flex-center mb-1">
            <input type="date"
                  id="end_date_${this.nextDateRangeId}"
                  name="project[project_date_ranges_attributes[][end_date]]"
                  min="1950-12-31"
                  max="${moment().format('YYYY-MM-DD')}"
                  data-admin-panel--dashboard-projects--fixed-date-range-target="endDate"
                  data-admin-panel--dashboard-projects--project-form-target="endDateInput"
                  data-action="change->admin-panel--dashboard-projects--fixed-date-range#updateStartDate
                               change->admin-panel--dashboard-projects--project-form#validateForm
                               change->admin-panel--dashboard-projects--project-form#validateExistingEndDates" />
            <span class="delete-button-container ml-3">
              <i class="material-icons cursor-pointer"
                 data-action="click->admin-panel--dashboard-projects--project-form#deleteDateRange">
                delete
              </i>
            </span>
          </div>
          <div class="error-date-out-of-range" hidden>
            Please enter a date between 12/31/1950 and the current date.
          </div>
        </div>
      </div>
    `

    return html
  }

  // TODO: This controller could be simplified / improved in a number of ways:
  // 1. validation functions can be removed and replaced by "required" attribute on HTML inputs
  // 2. after removing the validation functions as per (1.), this controller can be
  //    renamed to add_remove_date_range_controller
  // 3. dateRangeHtml() can be replaced by a <template> tag:
  //    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/template

  /* Validation Functions */
  validateProjectSource () {
    if (!this.hasProjectSourceInputTarget) {
      return true
    }

    return !!this.projectSourceInputTarget.value
  }

  validateEndDates () {
    let endDatesValid = true

    this.endDateInputTargets.forEach(endDateInput => {
      const endDateNotOutOfRange = this.validateEndDateNotOutOfRange(endDateInput)

      endDatesValid &&= !!endDateInput.value
      endDatesValid &&= endDateNotOutOfRange
    })

    const dateRangesDoNotOverlap = this.validateDateRangesDoNotOverlap()
    endDatesValid &&= dateRangesDoNotOverlap

    return endDatesValid
  }

  validateEndDateNotOutOfRange (endDateInput) {
    const errMsgEndDateOutOfRange = endDateInput.closest('.date-range').querySelector('.error-date-out-of-range')

    // hide error message if end date input is blank
    if (!endDateInput.value) {
      errMsgEndDateOutOfRange.hidden = true
      return true
    }

    const endDate = moment(endDateInput.value)
    const today = moment()
    const nineteenFifty = moment('1950-12-31')

    const endDateOutOfRange = endDate.isSameOrAfter(today) || endDate.isBefore(nineteenFifty)

    // display error message if end date is out of range
    errMsgEndDateOutOfRange.hidden = !endDateOutOfRange

    return !endDateOutOfRange
  }

  validateDateRangesDoNotOverlap () {
    let dateRangesOverlap = false
    const existingRanges = []
    this.endDateInputTargets.forEach(endDateInput => {
      if (endDateInput.value) {
        const endDate = moment(endDateInput.value)
        const startDate = moment(endDate).subtract(1, 'year').add(1, 'day')

        existingRanges.forEach(existingRange => {
          const s = existingRange.startDate
          const e = existingRange.endDate

          if (endDate.isSameOrAfter(s) && endDate.isSameOrBefore(e)) {
            dateRangesOverlap = true
          }

          if (startDate.isSameOrAfter(s) && startDate.isSameOrBefore(e)) {
            dateRangesOverlap = true
          }
        })

        existingRanges.push({ startDate, endDate })
      }
    })

    // display error callout if there are overlapping date ranges
    document.querySelector('.error-date-ranges-overlap').hidden = !dateRangesOverlap

    return !dateRangesOverlap
  }

  validateExistingEndDates () {
    let hasExistingEndDate = false
    const existingEndDates = new Set(this.existingDateRangesValue)
    this.endDateInputTargets.forEach((endDateInput) => {
      hasExistingEndDate ||= existingEndDates.has(endDateInput.value)
    })

    document.querySelector('.error-duplicate-end-date').hidden = !hasExistingEndDate
  }

  validateUserCheckboxes () {
    let userCheckboxesValid = false
    this.userCheckboxTargets.forEach(checkbox => {
      userCheckboxesValid ||= checkbox.checked
    })

    return userCheckboxesValid
  }

  validateStatus () {
    if (!this.hasStatusRadioBtnTarget) {
      return true
    }

    let statusValid = false
    this.statusRadioBtnTargets.forEach(btn => {
      statusValid ||= btn.checked
    })

    return statusValid
  }

  validateForm () {
    // validateEndDates is a non-pure function with side effects, so it must be called first
    // - otherwise early return of '&&' operator will not trigger its side effects
    const endDatesValid = this.validateEndDates()

    const isValid = endDatesValid && this.validateProjectSource() &&
                    this.validateStatus() && this.validateUserCheckboxes()

    this.createBtnTarget.disabled = !isValid
  }
}
