import BaseController from './base_controller'

// Sets all input fields under containers to tabindex="-1" to disable tabbing into them
export default class CSSSetterController extends BaseController {
  static values = { cssClass: String }

  addClass (e) {
    this.element.classList.add(this.cssClassValue)
  }

  removeClass (e) {
    this.element.classList.remove(this.cssClassValue)
  }
}
