import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['expandable']

  // Display is the CSS property: 'block', 'flex', etc.
  // State can be either: 'expanded', 'collapsed' (default)
  static values = {
    display: String,
    state: { type: String, default: 'collapsed' }
  }

  toggle () {
    for (const el of this.expandableTargets) {
      if (el.classList.contains('hidden')) {
        el.classList.remove('hidden')
        el.hidden = false
        el.style.display = this.displayValue
        this.stateValue = 'expanded'
      } else {
        el.classList.add('hidden')
        el.hidden = true
        el.style.display = 'none'
        this.stateValue = 'collapsed'
      }
    }
  }

  expand () {
    const hidden = this.hiddenElements()
    // If no elements need to be expanded, return.
    if (hidden.length === 0) { return }

    // Expand nested elements.
    for (const el of hidden) {
      el.classList.remove('hidden')
      el.hidden = false
      el.style.display = this.displayValue
      el.stateValue = 'expanded'
    }

    // Set self as expanded
    this.stateValue = 'expanded'
  }

  collapse () {
    const visible = this.visibleElements()
    // If no elements need to be hidden, return.
    if (visible.length === 0) { return }

    // Collapse nested elements.
    for (const el of visible) {
      el.classList.add('hidden')
      el.hidden = true
      el.style.display = 'none'
      el.stateValue = 'collapsed'
    }

    // Set self to collapsed
    this.stateValue = 'collapsed'
  }

  // Returns all the targets for expansion/collapse that
  // are currently hidden.
  hiddenElements () {
    return this.expandableTargets.filter(
      el => el.classList.contains('hidden')
    )
  }

  // Returns all the targets for expansion/collapse that
  // are currently visible.
  visibleElements () {
    return this.expandableTargets.filter(
      el => !el.classList.contains('hidden')
    )
  }

  stateValueChanged () {
    this.dispatch('toggle', { detail: { state: this.stateValue } })
  }
}
