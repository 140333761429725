import BaseController from '../base_controller'

// Navigates through citations related to a resource.
export default class extends BaseController {
  static targets = []
  static values = {
    citableUri: String,
    pos: {
      type: Number,
      default: -1
    }
  }

  initialize () {
    super.initialize()
  }

  connect () {
    super.connect()
  }

  disconnect () {
    super.disconnect()
  }

  next () {
    const count = this.citationsCount()
    if (count <= 0) {
      return
    }

    this.posValue = this.posValue + 1
    if (this.posValue >= count) {
      this.posValue = 0
    }

    // If the citations are not present yet, as in the case of lazy
    // loading, click the section to render it and wait for it to load.
    if (this.citations().length === 0) {
      const citationPanel = document
        .getElementById('classification_citations_index')
      const section = citationPanel
        .querySelector(`[data-citable-uri='${this.citableUriValue}']`)
      const sectionHeader = section.querySelector('.citation-section-header')

      // Switch to Citations panel if not currently on it
      if (!this.viewingCitationsTab()) {
        document.querySelector("[data-tab-view='citations-view']").click()
      }

      // Scroll to header position while section is loading so the
      // spinner is visible.
      sectionHeader.click()
      sectionHeader.scrollIntoView({ behavior: 'smooth', block: 'start' })

      waitForEl(section, '.citation-show').then(() => {
        const toNavigate = this.citations()[this.posValue]
        note_and_citation_scrolling(toNavigate)
      })
    // Otherwise just navigate right away.
    } else {
      const toNavigate = this.citations()[this.posValue]
      note_and_citation_scrolling(toNavigate)
    }
  }

  // Determine whether currently viewing the Citations panel
  viewingCitationsTab () {
    return document.querySelector("[data-tab-content='notes-and-citations']").classList.contains('citations-view')
  }

  // Gets the number of citations currently under a given section.
  citationsCount () {
    const citationPanel = document.getElementById('classification_citations_index')
    if (citationPanel === null) {
      return -1
    }

    const citationPanelSection = citationPanel
      .querySelector(`[data-citable-uri='${this.citableUriValue}']`)
    const citationsCount = citationPanelSection
      .querySelector('.citation-section-count')
      .dataset
      .count

    return citationsCount
  }

  // Returns all Citation card DOM elements from the panel section in question.
  citations () {
    const citationPanel = document.getElementById('classification_citations_index')
    if (citationPanel === null) {
      return []
    }

    const citationPanelSection = citationPanel
      .querySelector(`[data-citable-uri='${this.citableUriValue}']`)
    const citations = citationPanelSection
      .querySelectorAll('.citation-show')
    return Array.from(citations)
  }
}
