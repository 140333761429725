import BaseController from '../base_controller'

export default class BaseAndExtractedCitationsFormattingController extends BaseController {
  connect () {
    super.connect()
    this.updateHeights()

    this.boundUpdateHeights = this.updateHeights.bind(this)
    window.addEventListener('resize', this.boundUpdateHeights)
  }

  disconnect () {
    window.removeEventListener('resize', this.boundUpdateHeights)
  }

  updateHeightsDelayed () {
    // setTimeout so this happens one frame after all other page modifications are made from the click
    setTimeout(() => { this.updateHeights() }, 1)
  }

  updateHeights () {
    this.setHeights(this.element.querySelectorAll('[data-ujs-id="citation-format-page"]'))
    this.setHeights(this.element.querySelectorAll('[data-ujs-id="citation-format-links"]'))
    this.setHeights(this.element.querySelectorAll('[data-ujs-id="citation-format-content"]'))
    this.setHeights(this.element.querySelectorAll('[data-ujs-id="citation-format-attribution"]'))
    this.setHeights(this.element.querySelectorAll('[data-ujs-id="citation-format-comment"]'))
    this.setHeights(this.element.querySelectorAll('[data-ujs-id="citation-format-footer"]'))
  }

  setHeights (sections) {
    let maxHeight = 0
    sections.forEach(section => {
      section.style.height = ''
      const height = section.offsetHeight

      if (height > maxHeight) maxHeight = height
    })

    sections.forEach(section => {
      section.style.height = `${maxHeight}px`
    })
  }
}
