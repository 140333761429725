import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dataReportIdField', 'dataReportTypeField']

  clearFields () {
    this.dataReportIdFieldTarget.value = ''
    this.dataReportTypeFieldTarget.value = ''
    this.element.style.display = 'none'

    const formId = this.dataReportIdFieldTarget.getAttribute('form')
    document.getElementById(formId).querySelector("input[type='submit']").click()
  }
}
