import BaseController from './base_controller'
import { fire } from '../lib/form_events'

export default class SubmitFormController extends BaseController {
  static targets = ['form']

  submit (e) {
    fire(this.formTarget, 'submit')
  }
}
