import BaseController from '../../base_controller'

export default class UsersSelect extends BaseController {
  static targets = ['checkbox', 'groupsDropdown']

  clearAllCheckboxes () {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })
  }

  toggleGroupsDropdown () {
    this.groupsDropdownTarget.hidden = !this.groupsDropdownTarget.hidden
  }

  prefillGroupUsers (event) {
    const idPrefixLength = 'checkbox_'.length

    this.checkboxTargets.forEach(checkbox => {
      const checkboxId = Number(checkbox.id.slice(idPrefixLength))

      if (event.params.groupUserIds.includes(checkboxId)) {
        checkbox.checked = true
      }
    })

    this.toggleGroupsDropdown()
  }
}
