import BaseController from './base_controller'

export default class TippyHideOnClickController extends BaseController {
  connect () {
    super.connect()

    if (this.element.closest('.tippy-box')) {
      document.addEventListener('click', this.hideTooltips.bind(this))
    }
  }

  disconnect () {
    super.disconnect()

    if (this.element.closest('.tippy-box') && document) {
      document.removeEventListener('click', this.hideTooltips.bind(this))
    }
  }

  hideTooltips () {
    document.querySelectorAll('[data-controller="tippy-tooltip"]').forEach(tippyHolder => {
      if (!tippyHolder._tippy) return
      if (!tippyHolder._tippy.state.isVisible) return
      if (tippyHolder._tippy.popper.querySelector('[data-controller="tippy-hide-on-click"]') !== this.element) return

      tippyHolder._tippy.hide()
    })
  }
}
